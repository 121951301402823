import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  modules.push(value.default)
  return modules
}, [])
const routes = [
  {
    path: '',
    name: 'home',
    component: () => import('@/views/portal/home'),
  },
  {
    path: '/ocspQuery',
    component: () => import('@/views/ocspQuery'),
    name: 'ocspQuery',
  },
  {
    path: '/dedicatedInquiry',
    component: () => import('@/views/ocspQuery'),
    name: 'dedicatedInquiry',
  },
  {
    path: '/paymentH5',
    component: () => import('@/views/paymentH5/index'),
    name: 'paymentH5',
  }, {
    path: '/payment',
    component: () => import('@/views/payment/index'),
    name: 'payment',
  },
  {
    path: '/ukey-guide',
    component: () => import('@/views/ukeyGuide'),
    name: 'ukeyGuide',
  },
  {
    path: '/ukey-basicsGuide',
    component: () => import('@/views/ukeyBasicsGuide'),
    name: 'ukeyGuide',
  },
  {
    path: '/online-unlock',
    component: () => import('@/views/onlineUnlock'),
    meta: {
      scrollToTop: true
    }
  },
  {
    path: '/online-renewal',
    component: () => import('@/views/onlineRenewal'),
    meta: {
      scrollToTop: true
    }
  },
  {
    path: '/online-sealChange',
    component: () => import('@/views/onlineSealChange'),
    meta: {
      scrollToTop: true
    }
  },
  {
    path: '/online-sealRenewal',
    component: () => import('@/views/onlineSealRenewal'),
    meta: {
      scrollToTop: true
    }
  },
  {
    path: '/cloudCertificate',
    component: () => import('@/views/cloudCertificate/index.vue'),
    name: 'cloudCertificate',
  },
  {
    path: '/identityAuth',
    component: () => import('@/views/identityAuth/index.vue'),
    name: 'identityAuth',
  },
  ...modules,
  {
    path: '/404',
    component: () => import('./../components/404.vue'),
    name: 'notfound',
  },
  {
    path: '*',
    redirect: '/404'
  }
]
const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
    x: 0
  }),
  mode: 'history',
  // base: '/',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //    路由发生变化改变description和keyword
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }

  next()
})
export default router