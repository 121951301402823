import request from '@/utils/request'

// 获取消息数量
export function getUnreadMsgCount () {
  return request({
    url: '/portal/msg/get_unread_msg_count',
    method: 'get'
  })
}
// 退出登录
export function removeToken () {
  return request({
    url: '/auth/oauth/remove/token',
    method: 'post'
  })
}
// 向安全手机号发送验证码
export function sendCurrentSmsCode (params) {
  return request({
    url: '/portal/account/send_current_sms_code',
    method: 'get',
    params
  })
}
// 获取个人解绑验证码
export function sendPersonUnbindSend (params) {
  return request({
    url: '/portal/auth/person/unbind/send',
    method: 'get',
    params
  })
}
// 个人解绑
export function sendPersonUnbind (params) {
  return request({
    url: '/portal/auth/person/unbind',
    method: 'post',
    params
  })
}
// 向新手机号发送验证码
export function sendAccountMobile (params) {
  return request({
    url: '/portal/account/get_sms_verification_code',
    method: 'get',
    params
  })
}
// 向邮箱发送验证码
export function sendAccountEmail (params) {
  return request({
    url: '/portal/account/send_account_email',
    method: 'get',
    params
  })
}
// 通过验证码验证手机号
export function validCurrentSmsCode (params) {
  return request({
    url: '/portal/account/valid_current_sms_code',
    method: 'post',
    params
  })
}
// 检查手机号是否可用
export function getMobileVerify (params) {
  return request({
    url: '/portal/account/mobile_verify',
    method: 'get',
    params,
    skipErr: true
  })
}
// 检查邮箱是否可用
export function getEmailVerify (params) {
  return request({
    url: '/portal/account/verify_email',
    method: 'get',
    params
  })
}
// 更换邮箱
export function updateEmail (params) {
  return request({
    url: '/portal/account/update_email',
    method: 'post',
    params
  })
}
// 更换手机号
export function updateMobile (params) {
  return request({
    url: '/portal/account/update_mobile',
    method: 'post',
    params
  })
}
// 保存个人认证信息
export function saveAuthInfo (params) {
  return request({
    url: '/portal/auth/person/save-auth-info',
    method: 'post',
    params,
    // 设置错误提示方式为弹窗提示
    hintType: 'popup'
  })
}
// 获取个人刷脸认证二维码
export function getCreateFaceUrl (params) {
  return request({
    url: '/portal/auth/person/create-face-url',
    method: 'get',
    responseType: 'blob',
    params
  })
}
// 获取个人刷脸认证结果
export function getAuthPersonStatus (params) {
  return request({
    url: '/portal/auth/person/get-auth-person-status',
    method: 'get',
    params
  })
}
// 发送解绑验证码
export function customerUnbindSend (params) {
  return request({
    url: '/portal/account/customer/unbind/send',
    method: 'get',
    params
  })
}
// 获取业务单列表
export function getTransactionListPage (params) {
  return request({
    url: '/portal/transaction/list_page',
    method: 'get',
    params
  })
}
// 取消业务单
export function transactionCancel (params) {
  return request({
    url: '/portal/transaction/cancel',
    method: 'post',
    params
  })
}

// 获取我的证书
export function getCertListPageMycert (params) {
  return request({
    url: '/portal/cert/list_page_my_cert',
    method: 'get',
    params,
    skipErr: true,
  })
}
// 获取证书详情
export function getCertInfo (params) {
  return request({
    url: '/portal/cert/cert_info',
    method: 'get',
    params
  })
}
// 分页查询当前用户的业务单发票列表
export function getInvoiceListPage (params) {
  return request({
    url: '/portal/invoice/list_page',
    method: 'get',
    params
  })
}
// 分页查询当前用户的订单发票列表
export function getOrderListPage (params) {
  return request({
    url: '/portal/invoice/order_list_page',
    method: 'get',
    params
  })
}
// 申请发票
export function getInvoiceGetCode (params) {
  return request({
    url: '/portal/invoice/get_invoice_qr_code',
    method: 'post',
    params
  })
}
// 订单 获取开票二维码
export function getOrderInvoiceGetCode (params) {
  return request({
    url: '/portal/invoice/get-order-invoice-qrcode',
    method: 'post',
    params
  })
}

// 根据业务单id查询物流信息
export function getlogisticsByTransId (params) {
  return request({
    url: '/portal/transaction/logistics-by-trans-id',
    method: 'get',
    params
  })
}
// 根据业务单id查询pdf
export function getPrivateBucketUrl (params) {
  return request({
    url: '/portal/file/get_private_bucket_url',
    method: 'get',
    params
  })
}

// 分页查询当前账户的所有订单信息
export function getOrderList (params) {
  return request({
    url: '/portal/order/order-page',
    method: 'get',
    params
  })
}
// 根据订单号获取网络身份认证 订单详细信息
export function getNetworkDetail (params) {
  return request({
    url: '/portal/order/get-identify-detail',
    method: 'get',
    params
  })
}
// 云证书业务单详情
export function getCloudDetail (params) {
  return request({
    url: '/portal/order/cloud-cert-detail',
    method: 'get',
    params
  })
}

// 集成中心-根据订单号查询订单详情
export function getIntegrationDetail (params) {
  return request({
    url: '/portal/order/get-integration-detail',
    method: 'get',
    params
  })
}
// 集成中心 企业购买网络身份认证业务详情
export function getIdentifyDetail (params) {
  return request({
    url: '/portal/order/integration-identify-detail',
    method: 'get',
    params
  })
}

// 个人中心-集成中心 云证书列表查询
export function getCloudList (params) {
  return request({
    url: '/portal/order/integration-cloud-list',
    method: 'get',
    params
  })
}
// 个人中心-集成中心 网络身份认证列表查询
export function getNetworkList (params) {
  return request({
    url: '/portal/order/integration-identify-list',
    method: 'get',
    params
  })
}
// 保存公钥 （用户传入）
export function saveSecret (data) {
  return request({
    url: '/portal/customer/save-secret',
    method: 'post',
    data
  })
}
// 添加企业白名单绑定
export function addIpWhite (params) {
  return request({
    url: '/portal/customer/add-ip-white',
    method: 'get',
    params
  })
}
// 查看APPSecret验证码验证
export function viewSecret (params) {
  return request({
    url: '/portal/customer/secret/valid',
    method: 'post',
    params
  })
}
// 获取查看企业APPSecret验证码
export function getSecretSend (params) {
  return request({
    url: '/portal/customer/get-secret/send',
    method: 'get',
    params
  })
}
// 企业解绑验证码验证
export function unbindCustomer (params) {
  return request({
    url: '/portal/customer/customer/unbind/valid',
    method: 'post',
    params
  })
}
// 获取企业解绑验证码
export function getCustomerUnbind (params) {
  return request({
    url: '/portal/customer/customer/unbind/send',
    method: 'get',
    params
  })
}

// 保存配置
export function pageSaveOrUpdate (data) {
  return request({
    url: '/portal/page/saveOrUpdate',
    method: 'post',
    data
  })
}
// 根据appId 获取页面配置
export function pageByAppid (params) {
  return request({
    url: '/portal/page/get-by-appid',
    method: 'get',
    params
  })
}


// 账户重新绑定企业（针对由于已绑定其他账户而无法绑定成功的企业）
export function customerRebind (params) {
  return request({
    url: '/portal/customer/rebind',
    method: 'get',
    skipErr: true,
    params
  })
}


// 删除APPID指定ip
export function removeIpWhite (data) {
  return request({
    url: '/portal/customer/remove-ip-white',
    method: 'post',
    skipErr: true,
    data
  })
}


// 申请增值税发票上传付款凭证
export function uploadVoucher (data) {
  return request({
    url: '/portal/file/upload-voucher',
    method: 'post',
    skipErr: true,
    data
  })
}


// 重新申请开专票 重新创建一条未开始的
export function vatInvoiceReapply (params) {
  return request({
    url: '/portal/invoice/reapply',
    method: 'post',
    skipErr: true,
    params
  })
}
// 重新申请开普票 重新创建一条未开始的
export function invoiceReapply (params) {
  return request({
    url: '/portal/invoice/reapply-trans',
    method: 'post',
    skipErr: true,
    params
  })
}
// 申请发票
export function applyInvoice (data) {
  return request({
    url: '/portal/invoice/apply-invoice',
    method: 'post',
    skipErr: true,
    data
  })
}
// 申请开具增值税专票
export function applyVatInvoice (data) {
  return request({
    url: '/portal/invoice/apply-vat-invoice',
    method: 'post',
    skipErr: true,
    data
  })
}

// 查看增值税发票开票审核进度
export function vatInvoiceViewStatus (params) {
  return request({
    url: '/portal/invoice/view-status',
    method: 'get',
    skipErr: true,
    params
  })
}
// 查看普票开票审核进度
export function invoiceViewStatus (data) {
  return request({
    url: '/portal/invoice/query-trans-invoice-status',
    method: 'post',
    skipErr: true,
    data
  })
}
// 取消认证
export function cancelVerify (params) {
  return request({
    url: '/portal/customer/cancel-verify',
    method: 'post',
    skipErr: true,
    params
  })
}

// 取消订单
export function cancelOrder (params) {
  return request({
    url: '/portal/order/cancel-order',
    method: 'post',
    skipErr: true,
    params
  })
}
