<template>
  <div class="userAgreement">
    <h1>《豸信CA隐私政策》</h1>
    <p>发布日期：2019年10月23日</p>
    <p>生效日期：2019年10月23日</p>
    <p>欢迎您使用豸信CA服务！</p>
    <p>山东豸信数字认证服务有限公司（以下称“我们”或“豸信CA”）非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《豸信CA隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息。</p>
    <p>
      本隐私政策与您所使用的豸信CA以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。本政策属于《豸信CA用户协议》不可分割的一部分。您同意《豸信CA用户协议》，即视为您已经阅读并同意本政策的全部内容，如果您不同意本政策的条款，应停止使用服务。
    </p>
    <p>本隐私政策将帮助您了解以下内容：</p>
    <ul>
      <li>1、我们如何收集和使用您的个人信息</li>
      <li>2、我们如何使用 Cookie 和同类技术</li>
      <li>3、我们如何共享、转让、公开披露您的个人信息</li>
      <li>4、我们如何保护和保存您的个人信息</li>
      <li>5、您如何管理个人信息</li>
      <li>6、未成年人信息的保护</li>
      <li>7、本政策如何更新</li>
      <li>8、如何联系我们</li>
    </ul>
    <h2>一、我们如何收集和使用您的个人信息</h2>
    <p>为了向您提供服务、优化我们的服务以及保障账号安全，我们会按照如下方式收集、使用您的个人信息：</p>
    <p>（一）、实现数字证书申请所必须的功能</p>
    <ul>
      <li>1、账号注册信息：当您首次注册登录豸信CA，您需要提供您的手机号码，并创建登录密码，上述信息为您使用豸信CA所必需，若您不提供这类信息，您将无法正常使用我们的服务。</li>
      <li>2、实名认证信息：在您申请新办数字证书过程中，您需要进行实名认证，您需要提供您个人信息如真实姓名、身份证信息、手机号码、银行卡信息，以及面部生物特征信息。收集此类信息是为了满足相关法律法规的实名制要求，若您不提供这类信息，您将无法完成实名认证，亦无法完成数字证书的申请，但不会影响您浏览豸信CA的网站。</li>
    </ul>
    <p>（二）、向您提供产品或服务</p>
    <p>当您使用豸信CA服务时，为了维护我们服务的正常运行，改进及优化我们的服务体验以及保障您的账号安全，我们会收集您下述信息：</p>
    <ul>
      <li>1、设备信息：我们会根据您在使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备型号、操作系统版本、唯一设备标识符、IP地址、软件版本号等软硬件特征信息）。</li>
      <li>2、日志信息：我们会收集您对我们服务的详细使用情况，作为有关日志保存，包括接入网络的方式、类型和状态、网络质量数据、操作日志、服务日志信息。</li>
      <li>3、您通过我们的客服、网站留言或参加我们举办的活动时所提交的信息：当您与我们联系时，我们可能会保存您的通信/通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</li>
      <li>4、寄送服务信息：当您向我们申请数字证书新办或补办时，您需要向我们提供您的收件信息，包括收件人姓名、收件人手机号/座机、收件地址。</li>
    </ul>
    <p>（三）、其他用途</p>
    <p>
      我们将基于本政策未载明的其他特定目的收集您的信息时，会事先征求您的同意。我们收集及使用上述信息的目的是为了更好地经营豸信CA产品和服务，并且会通过包括但不限于通过网站通知、电子邮件、电话或短信息等方式通知。如我们停止运营豸信CA产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息，将按照工业和信息化部《电子认证服务管理办法》的规定，向工业和信息化部报告，并与其他电子认证服务机构就业务承接进行协商，作出妥善安排。
    </p>
    <p>（四）、例外</p>
    <p>您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</p>
    <ul>
      <li>1、与国家安全、国防安全有关的；</li>
      <li>2、与公共安全、公共卫生、重大公共利益有关的；</li>
      <li>3、与犯罪侦查、起诉、审判和判决执行等有关的；</li>
      <li>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</li>
      <li>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</li>
      <li>6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</li>
      <li>7、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</li>
      <li>8、法律法规规定的其他情形。</li>
    </ul>
    <h2>二、我们如何使用 Cookie 和同类技术</h2>
    <p>为使您获得更轻松的访问体验，您使用我们的产品或服务时，我们可能会通过采用各种技术收集和存储您访问豸信CA服务的相关数据，在您访问或再次访问豸信CA服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。包括使用小型数据文件识别您的身份，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的登录状态以及账户安全。这些数据文件可能是Cookie、Flash
      Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。</p>
    <p>我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机上保存的所有 Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝
      Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问我们的网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除。</p>
    <h2>三、我们如何共享、转让、公开披露您的个人信息</h2>
    <p>（一）共享</p>
    <p>我们不会与豸信CA以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
    <ul>
      <li>1、事先获得您明确的同意或授权；</li>
      <li>2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</li>
      <li>3、只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</li>
      <li>4、应您合法需求，协助处理您与他人的纠纷或争议；</li>
      <li>5、应您的监护人合法要求而提供您的信息；</li>
      <li>6、根据与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</li>
      <li>7、基于符合法律法规的社会公共利益而使用。</li>
      <li>8、与授权的供应商和服务提供方共享：为实现本隐私政策中声明的目的，我们会与我们的供应商和服务提供方共享您的某些个人信息，具体如下：</li>
      <ol>
        <li>我们会将您的真实姓名、身份证信息、手机号码共享给实名认证机构；</li>
        <li>我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的供应商和服务提供方无权将共享的用户信息用于任何其他用途。</li>
        <li>我们将信息发送给支持我们业务的供应商、服务提供商，这些支持包括提供基础技术服务等专业服务。例如，在您申请实名认证时，我们会将您的个人信息传输至实名认证服务提供方，以便其完成对您的实名认证。</li>
      </ol>
    </ul>
    <p>（二）转让</p>
    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <ul>
      <li>1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。</li>
      <li>2、在豸信CA服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。</li>
    </ul>
    <h2>（三）公开披露</h2>
    <p>我们仅会在以下情况下，才会披露您的信息：</p>
    <ul>
      <li>1、根据您的需求，在您明确同意的披露方式下披露您所指定的信息；</li>
      <li>
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在向相关强制性的行政执法或司法机关披露后且前述机关允许的前提下，我们会及时通过合理的方式通知您。
      </li>
    </ul>
    <h2>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</h2>
    <p>以下情形中，我们共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
    <ul>
      <li>1、与国家安全、国防安全直接相关的；</li>
      <li>2、与公共安全、公共卫生、重大公共利益直接相关的；</li>
      <li>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</li>
      <li>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</li>
      <li>5、您自行向社会公众公开的个人信息；</li>
      <li>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</li>
    </ul>
    <h2>四、我们如何保护和保存您的个人信息</h2>
    <p>我们在中华人民共和国境内运营中收集和产生的信息，存储在中国境内。</p>
    <p>我们将采取以下手段保护您的信息：</p>
    <p>（一）数据安全技术措施</p>
    <p>
      我们会采用符合业界标准的安全防护措施，安全技术解决方案来防止您的信息遭到未经授权的访问、使用、篡改,避免数据的损坏丢失或泄露。数字证书数据存储在屏蔽机房，屏蔽级别达到涉密C级标准，并与外网隔离；用户数据存储在机房专用区域，24小时监控和专人值班，门禁需多人才能打开；数据通讯经过加密机加密传输，保证数据安全。目前，我们在信息安全方面已达到了《中华人民共和国电子签名法》和《电子认证服务管理办法》要求、符合GB/T2887-2011《计算机场地通用规范》中B级标准，并通过了工业和信息化部的安全检查。
    </p>
    <p>（二）我们为保护个人信息采取的其他安全措施</p>
    <p>
      我们通过建立信息资产管理程序及信息资产分级分类管理规定来规范信息和数据的存储和使用。同时，我们仅允许有必要知晓这些信息的员工访问您的信息，并为此设置了严格的访问权限控制和监控机制。对于关键岗位员工录用前我们都会经过背景调查，我们同时要求可能接触到您的信息的所有人员签订保密协议并履行相应的保密义务。我们也会定期开展关于个人信息和隐私保护的相关培训，不断提升员工对于用户个人信息和隐私的保护意识。
    </p>
    <p>（三）个人隐私数据泄露事件处置</p>
    <p>
      在不幸发生个人隐私数据泄露事件后，我们将在30个工作日内向您告知：个人隐私数据泄露的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人隐私数据泄露事件的处置情况。
    </p>
    <h2>五、您如何管理个人信息</h2>
    <p>我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</p>
    <p>（一）访问、更正和删除</p>
    <p>我们鼓励您更新和修改您的信息以使其更准确有效。您有权访问您的信息，并根据对应信息的管理方式自行完成或要求我们进行修改、补充和删除。我们将采取适当的技术手段，尽可能保证您可以访问、更正自己的信息。</p>
    <p>在访问、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障信息安全。</p>
    <p>（二）注销</p>
    <p>在您需要终止使用我们的服务时，您可以申请注销您的账户，您可以联系我们的人工客服（电话：400-040-2013或400-030-2013），申请注销您的账户。</p>
    <p>我们在此善意地提醒您，您注销账户的行为会使您无法继续使用豸信CA的相关服务，包括数字证书相关业务申请办理的服务。注销账户后您的个人信息会保持不可被检索、访问的状态，我们将不会再使用或对外提供与该账户相关的个人信息，但您在使用豸信CA服务期间提供或产生的信息我们仍需按照监管要求的保存5年以上，且在保存的时间内依法配合有权机关的查询。</p>
    <h2>六、未成年人信息的保护</h2>
    <p>我们重视未成年人的信息保护，如您为未成年人的，您不得使用我们的服务或向我们提供信息。我们亦不会主动收集未成年人个人信息，对于您主动向我们提供的未成年人个人信息，我们将根据国家相关法律法规及本隐私政策的规定保护未成年人的个人信息，并设法尽快删除相关个人信息。</p>
    <h2>七、本政策如何更新</h2>
    <p>（一）为了给您提供更好的服务，本隐私政策也会随之更新。我们会通过在豸信CA网站上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问豸信CA以便及时了解最新的隐私政策。</p>
    <p>（二）对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。本政策所指的重大变更包括但不限于：</p>
    <ul>
      <li>（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</li>
      <li>（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</li>
      <li>（3）个人信息共享、转让或公开披露的主要对象发生变化；</li>
      <li>（4）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</li>
      <li>（5）个人信息安全影响评估报告表明存在高风险时。</li>
    </ul>
    <h2>八、如何联系我们</h2>
    <p>如您对本隐私政策有任何问题、意见或建议，请拨打我们的客服电话（400-040-2013或400-030-2013）的方式与我们联系。一般情况下，我们将在三十天内回复。</p>
  </div>
</template>

<script>
export default {
  props: ['showPrivacy'],
  methods: {
    privacyClose () {
      this.$emit('privacyClose', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
