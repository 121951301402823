
let baseURL
let patternApi
// 数字证书助手id
let APPID
// 数字证书助手id
let SEALAPPID
// 签章客户端id
let CLIEN_SIDE_ID
if (process.env.VUE_APP_ENV === 'serve') {
  // baseURL = 'http://192.168.105.189:8091'
  baseURL = 'https://gateway-test.zxca.cn'
  // baseURL = 'https://gateway-dev.zxca.cn'
  patternApi = 'http://192.168.105.26:8888'
  APPID = 'https://gateway.zxca.cn/client/client-upgrade/cert-assistant/win/latest-download'
  SEALAPPID = 'https://gateway.zxca.cn/client/client-upgrade/seal-assistant/win/latest-download'
  CLIEN_SIDE_ID = 'https://gateway.zxca.cn/client/client-upgrade/sign-client/win/latest-download'
} else if (process.env.VUE_APP_ENV === 'pre') {
  baseURL = 'https://gateway.cloudsecca.com'
  patternApi = 'https://pattern.cloudsecca.com'
  APPID = 'https://gateway.cloudsecca.com/client/client-upgrade/cert-assistant/win/latest-download'
  SEALAPPID = 'https://gateway.cloudsecca.com/client/client-upgrade/seal-assistant/win/latest-download'
  CLIEN_SIDE_ID = 'https://gateway.cloudsecca.com/client/client-upgrade/sign-client/win/latest-download'
} else if (process.env.VUE_APP_ENV === 'dev') {
  baseURL = 'https://gateway-dev.zxca.cn'
  patternApi = 'https://pattern-test.zxca.cn'
  APPID = 'https://gateway.zxca.cn/client/client-upgrade/cert-assistant/win/latest-download'
  SEALAPPID = 'https://gateway.zxca.cn/client/client-upgrade/sign-client/win/latest-download'
} else if (process.env.VUE_APP_ENV === 'test') {
  baseURL = 'https://gateway-test.zxca.cn'
  patternApi = 'https://pattern-test.zxca.cn'
  APPID = 'https://gateway.zxca.cn/client/client-upgrade/cert-assistant/win/latest-download'
  SEALAPPID = 'https://gateway.zxca.cn/client/client-upgrade/seal-assistant/win/latest-download'
  CLIEN_SIDE_ID = 'https://gateway.zxca.cn/client/client-upgrade/sign-client/win/latest-download'
} else if (process.env.VUE_APP_ENV === 'prod') {
  baseURL = 'https://gateway.zxca.cn'
  patternApi = 'https://pattern.zxca.cn'
  APPID = 'https://gateway.zxca.cn/client/client-upgrade/cert-assistant/win/latest-download'
  SEALAPPID = 'https://gateway.zxca.cn/client/client-upgrade/seal-assistant/win/latest-download'
  CLIEN_SIDE_ID = 'https://gateway.zxca.cn/client/client-upgrade/sign-client/win/latest-download'
} else if (process.env.VUE_APP_ENV === 'dist') {
  baseURL = CONFIGD.baseURL
  patternApi = CONFIGD.patternURL
  APPID = CONFIGD.APPID
  SEALAPPID = CONFIGD.SEALAPPID
  CLIEN_SIDE_ID = CONFIGD.CLIEN_SIDE_ID
}

export default {
  baseURL,
  patternApi,
  APPID,
  SEALAPPID,
  CLIEN_SIDE_ID
}