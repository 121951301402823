<template>
  <div class="banner-box">
    <div class="text">
      <slot name="text">
        <div class="title">{{title}}</div>
        <div class="hint">{{hint}}</div>
      </slot>
    </div>
    <div class="banner-bg-box" :style="`transform: translateY(${translateY}px);`">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'hint'],
  data() {
    return {
      translateY: 0
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 564) {
        this.translateY = scrollTop / 2
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.banner-box {
  position: relative;
  overflow: hidden;
  height: 564px;
  width: 100%;
  background: #f0f4ff;
  .text {
    position: absolute;
    max-width: 1300px;
    width: 100%;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #303b50;
    z-index: 9;
    .title {
      font-size: 40px;
      line-height: 53px;
      font-weight: 700;
    }
    .hint {
      margin-top: 40px;
      font-size: 21px;
      line-height: 27px;
      max-width: 666px;
    }
    .hint-multi {
      margin-top: 24px;
      font-size: 16px;
      line-height: 30px;
      max-width: 666px;
    }
    .el-button {
      margin-top: 56px;
      width: 102px;
    }
  }
  ::v-deep.banner-bg-box {
    max-width: 1300px;
    margin: 0 auto;
    height: 100%;
    width: 92%;
    transform: all 0.2;
    position: relative;
    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>