<template>
  <div class="userAgreement">
    <h1>数字证书申请及使用协议</h1>
    <p>
      本协议由山东豸信认证服务有限公司（下称“豸信CA”）与数字证书用户（下称“用户”或“您”）之间签署，本协议具有合同效力。豸信CA是经国家有关管理机关审批设立的电子认证服务机构，作为权威的第三方安全认证机构，通过数字证书注册机构（以下简称“RA”）向用户发放数字证书，为用户网上交易提供信息安全保障，有权与用户签署本协议。豸信CA作为权威、可信、公正的第三方电子认证服务机构，根据国家相关法律、法规，为用户提供合法的数字证书申请、审核、签发和管理等电子认证服务。为明确各方权利和义务，豸信CA和用户就数字证书的申请和使用等事宜达成以下协议，共同遵守执行。用户在申请使用豸信CA签发的数字证书之前，应先阅读并同意本协议。
    </p>
    <h2>第一条 定义</h2>
    <p>1. 数字证书：电子认证服务机构签发的包含数字证书使用者身份信息和公开密钥的电子文件。</p>
    <p>2. 电子认证服务机构（CA）：工业和信息化部批准的电子认证服务机构和国家密码管理局批准的电子政务电子认证服务机构，遵照《中华人民共和国电子签名法》为用户提供数字证书相关的电子认证服务。</p>
    <p>3. 证书注册机构（RA）：经CA机构授权接收公钥证书的申请、注销和查验申请材料的机构，包括证书注册中心及受理点。</p>
    <p>4. 用户：数字证书持有人以及申请使用数字证书的主体。</p>
    <h2>第二条 声明</h2>
    <p>1. 您应在申请数字证书以前务必仔细阅读本协议，若您不同意本协议的任意内容，或者无法准确理解豸信CA对条款的解释，请不要在网络申请界面对本协议点击同意或书面签名接受本协议；若您在网络申请界面对本协议点击同意或书面签名接受本协议，则表示您对本协议的全部内容已充分阅读并认可和同意遵守，同时，承诺遵守中国法律、法规、规章及其他政府规范性文件的规定，
      如有违反而造成任何法律后果，您将以本人名义独立承担所有相应的法律责任。</p>
    <p>2. 您在网络申请界面点击同意本协议或书面签名接受本协议即表示您同意通过豸信CA申请数字证书，以及同意授权RA机构将您的个人身份信息或单位信息传输至豸信CA用于申请数字证书。</p>
    <p>3. 若不同意本协议全部或部分条款，请勿申请使用豸信CA的数字证书。用户一旦完成豸信CA数字证书的下载或初次使用，即表明同意接受并愿意遵守本协议的所有条款。</p>
    <h2>第三条 申请</h2>
    <p>1. 用户应遵循诚实守信原则。用户同意通过RA申请数字证书时，承诺在申请证书时提供真实、完整和准确的信息及证明材料，并在这些信息、资料发生改变时及时通知原RA。如因故意或过失未提供真实、完整和准确的信息或资料改变后未及时通知豸信CA或原RA，导致CA机构签发证书错误，造成相关各方损失的，由用户承担相关责任。</p>
    <p>2. 用户须使用经豸信CA认可并且用户通过合法途径获得的相关软件。</p>
    <p>3. 豸信CA应积极响应用户发出的证书申请请求，及时对用户申请数字证书时提交的信息进行审核。如果由于设备或网络故障而导致签发数字证书错误、延迟、中断或者无法签发，豸信CA仅提供再次签发服务，不承担任何赔偿责任。</p>
    <p>4. 用户在获得数字证书时应及时验证此证书所匹配的用户信息，如无异议则视为接受证书并承担该证书使用的责任和后果。</p>
    <h2>第四条 使用</h2>
    <p>1. 数字证书用于网络上的用户身份标识、数字签名验证及密钥分配，各应用系统可根据需要对其用途进行定义，但不包括涉及违反国家法律、法规或危害国家安全的用途。</p>
    <p>2. 用户应确保其应用系统能为数字证书提供安全的应用环境，若因网络、主机、操作系统或其他软硬件环境等存在安全漏洞，由此导致的安全事故及相关后果，豸信CA不承担责任。</p>
    <p>3. 用户应合法使用豸信CA发放的数字证书，并对使用数字证书的行为负责：
    <ul>
      <li>（1） 使用证书的行为应符合全部适用的法律法规；</li>
      <li>（2） 使用证书的行为应符合用户真实意愿或者仅为了处理已获得授权的事务；</li>
      <li>（3） 使用证书的行为应符合本协议约定的使用范围和条件。</li>
    </ul>
    </p>
    <p>4. 用户应当采取必要手段来保障证书的私钥和相关密码的安全存储、使用备份等，应当妥善保管数字证书以及调用数字证书的验证码或密码，不得泄漏或交付他人。如用户因故意或过失导致数字证书遭盗用、冒用、伪造或者篡改，用户应当自行承担相关责任。</p>
    <p>5. 数字证书对应的私钥为用户本身访问和使用，用户对使用数字证书的行为负责。所有使用数字证书在网络上签署电子文件或进行其他活动均视为用户所为，因此而产生的相关后果应当由用户自行承担。</p>
    <p>6. 如用户使用的数字证书私钥和相关密码泄漏、丢失，或者用户不希望继续使用数字证书，或者用户主体不存在，用户或法定权利人应当立即到原RA申请吊销或撤销该数字证书，相关手续遵循RA的规定。</p>
    <p>7. 数字证书一律不得转让、转借或转用。因转让、转借或转用而产生的相关后果应当由用户自行承担。</p>
    <p>8. 数字证书的有效期从签发之日计算，证书有效期将签发于该证书中。</p>
    <p>9. 用户有按期缴纳数字证书服务费的义务，一旦用户接受数字证书，如果用户在证书服务期内退出数字证书服务体系，豸信 CA 将不退还剩余时间的服务费用。</p>
    <p>10. 硬件介质（UKey）保质期为一年。</p>
    <h2>第五条 更新</h2>
    <p>1. 如遇证书中的信息发生变更，用户应及时通过原RA向CA机构申请更新证书。若用户逾期没有更新证书，因此而产生的相关后果应当由用户自行承担。</p>
    <p>2. 豸信CA有权因安全风险因素要求用户更新数字证书。用户在收到数字证书更新通知后，应在规定的期限内到原RA更换。若逾期用户没有更新证书，所引起的后果由用户自行承担。</p>
    <h2>第六条 吊销及撤销</h2>
    <p>1. 如遇数字证书私钥泄露丢失、证书中的信息发生重大变更、或用户不希望继续使用数字证书的情况，用户应当立即向豸信CA申请吊销证书。吊销手续遵循各注册机构的规定。用户应当承担在证书吊销之前所有因使用数字证书而造成的责任。</p>
    <p>2. 如果用户主体资格灭失（如企业注销等），法定代表人或授权代表通过原RA向CA机构请求吊销用户证书，用户主体及相关责任人应当承担其数字证书在吊销前所有使用数字证书而造成的相关后果。</p>
    <p>3. 用户知悉，如果豸信CA发现了用户证书的不当使用，或者用户证书被用于违法甚至犯罪行为，豸信CA有权直接吊销用户证书。</p>
    <p>4. 数字证书一旦被吊销，用户将不能再使用该证书。</p>
    <p>5. 对于下列情形之一，豸信CA有权主动撤销所签发的证书：
    <ul>
      <li>（1） 用户申请证书时，提供不真实信息或资料；</li>
      <li>（2） 证书对应的私钥泄露或出现其他证书的安全性得不到保证的情况；</li>
      <li>（3） 用户不能履行或违反了相关法律、法规和本协议所规定的责任和义务；</li>
      <li>（4） 法律、法规规定的其他情形。</li>
    </ul>
    </p>
    <p>6. 数字证书一旦被撤销，将产生数字证书自始溯及无效的法律后果。</p>
    <h2>第七条 责任承担</h2>
    <p>1. 用户损害豸信CA利益的，须向豸信CA赔偿全部损失。这些情形包括但不限于：
    <ul>
      <li>（1） 用户在申请数字证书时没有提供真实、完整、准确信息，或在这些信息变更时未及时通知RA；</li>
      <li>（2） 未能保护用户的私钥，或者没有使用必要的防护措施来防止用户的私钥遗失、泄密、被修改或被未经授权的人使用；</li>
      <li>（3） 用户知道自己的私钥已经失密或者可能已经失密而未及时告知有关各方、并终止使用；</li>
      <li>（4） 用户如果向依赖方传递信息时表述有误，而依赖方用证书验证了一个或多个数字签名后理所当然地相信这些表述，用户必须对这种行为的后果负责；</li>
      <li>（5） 证书的非法使用，即违反豸信 CA 对证书使用的规定，造成了豸信 CA 或有关各方的利益受到损失；</li>
      <li>（6） 用户有其他过错或未履行本协议的相关约定。</li>
    </ul>
    </p>
    <p>2. 如果用户依法使用豸信CA提供的认证服务进行民事活动而遭受损失的，豸信CA将给予相应赔偿，除非豸信CA能够证明其提供的服务是按照《电子签名法》等相关法律法规和豸信CA向主管部门备案的CPS实施的。以下损失不在豸信CA进行赔偿之列：
    <ul>
      <li>（1） 任何直接或间接的利润或收入损失、信誉或商誉损害、任何商机或契机损失、失去项目、以及失去或无法使用任何数据、无法使用任何设备、无法使用任何软件；</li>
      <li>（2） 由上述损失相应生成或附带引起的损失或损害。</li>
    </ul>
    </p>
    <p>3. 以下损失豸信CA将不承担责任：
    <ul>
      <li>（1） 非因豸信CA的行为而导致的损失；</li>
      <li>（2） 因意外事件或不可抗力而导致的损失，如罢工、战争、灾害、恶意代码病毒等。</li>
    </ul>
    </p>
    <p>4. 豸信CA对机构证书的赔偿上限为人民币贰仟元整，即￥2000元；对个人证书的赔偿上限为人民币肆佰元整，即￥400元；对事件性证书的赔偿上限为人民币捌佰元整，即￥800元；对设备证书的赔偿上限为人民币陆仟元整，即￥6000元；对服务器证书的赔偿上限为人民币捌仟元整，即￥8000元；对云端移动证书的赔偿上限为人民币捌佰元整，即￥800元。</p>
    <h2>第八条 其他</h2>
    <p>1. 本协议中涉及“原RA”“RA机构”的条款若因RA合并或撤销，即原RA不存在，则业务的受理与开展应到另行指定的RA进行。</p>
    <p>2. 豸信CA 有权对本协议进行修订，修订后的本协议将公布于豸信CA网站<a href="https://www.zxca.cn">（www.zxca.cn）</a>。如用户在本协议公布修订后一周后继续使用豸信提供的数字证书服务，即表明同意接受此等修订的约束。如果用户不予接受本协议中的约束，可以在上述期限内单方以书面形式向RA申请停止使用证书。</p>
    <p>3. 本协议的解释适用中华人民共和国法律。若发生任何纠纷或争议，首先应友好协商解决，协商不成的，争议方均可提请深圳国际仲裁院按照该会有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均具有约束力。</p>
    <p>4. 用户确认已经认真阅读并完全理解本协议中的各项规定，用户一旦在网络申请界面对本协议点击同意或书面签名接受本协议，即表明接受本协议的约束，本协议即时生效。</p>
  </div>
</template>

<script>
export default {
  props: ['showApplication'],
  methods: {
    applicationClose () {
      this.$emit('applicationClose', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
