<template>
  <div class="protocol">
    <!---页面主体--->
    <RouterView />
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.protocol {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 32px;
}
</style>
