import request from '@/utils/request'
// 生成实名认证链接
export function getAuthUrl (data) {
  return request({
    url: '/portal/real-name-product/get-auth-url',
    method: 'post',
    skipErr: true,
    data
  })
}
