
<template>
  <el-scrollbar v-loading="loading" class="pdfBox">
    <canvas class="canvas" v-for="page in pages" :id="'canvas' + page" :key="page"></canvas>
    <slot></slot>
  </el-scrollbar>
</template>
<script>
// pdf预览电子签章显示问题
//1. node_modules/pdfjs-dist/build/pdf.worker.js注释掉一行代码
// this.setFlags(_util.AnnotationFlag.HIDDEN);
//1. node_modules/pdfjs-dist/es5/build/pdf.worker.js注释掉一行代码
//  _this3.setFlags(_util.AnnotationFlag.HIDDEN);
import PDF from 'pdfjs-dist'

export default {
  name: '',
  props: {
    url: String
  },
  data() {
    return {
      width: 100,
      pdfDoc: null,
      pages: 0,
      loading: false,
      workerSrc: require('pdfjs-dist/build/pdf.worker.min')
    }
  },
  mounted() {
    this.loadFile(this.url)
  },
  watch: {
    url(val) {
      if (val) {
        this.loadFile(val)
      }
    }
  },
  methods: {
    /*
     * 加载PDF文件
     * url：后台提供的pdf地址或者项目中public/pdf文件（test.pdf）
     * 在需要的位置调用 this.loadFile(url)
     * */
    loadFile(url) {
      PDF.disableWorker = true
      PDF.GlobalWorkerOptions.workerSrc = this.workerSrc
      this.loading = true
      PDF.getDocument({
        url,
        cMapUrl: './cmaps/',
        cMapPacked: true
      }).then(pdf => {
        this.pdfDoc = pdf
        this.pages = this.pdfDoc.numPages
        this.$nextTick(() => {
          this.renderPage(1)
        })
      })
    },
    /*
     * 渲染PDF文件
     * */
    renderPage(num) {
      this.pdfDoc.getPage(num).then(page => {
        let canvas = document.getElementById('canvas' + num)
        let ctx = canvas.getContext('2d')
        let viewport = page.getViewport(1.3)
        let CSS_UNITS = 96.0 / 72.0
        canvas.height = Math.floor(viewport.height * CSS_UNITS)
        canvas.width = Math.floor(viewport.width * CSS_UNITS)
        //   canvas.style.width = viewport.width + 'px' // 画布的框架大小
        //   canvas.style.height = viewport.height + 'px'

        let renderContext = {
          transform: [CSS_UNITS, 0, 0, CSS_UNITS, 0, 0],
          canvasContext: ctx,
          viewport: viewport
        }
        page.render(renderContext)
        if (this.pages > num) {
          this.renderPage(num + 1)
        } else {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.pdfBox {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  //   height: 80vh;
  .el-scrollbar__view {
    position: relative;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
    margin-bottom: 0;
  }
  .canvas {
    width: 100%;
  }
}
</style>
