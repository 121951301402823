import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import store from './store'

import "@/assets/icons";
import '@/assets/styles/index.scss'
import VueCookie from 'vue-cookie'
Vue.use(VueCookie);
// 注册全局过滤器
import * as filters from '@/utils/filters.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) //插入过滤器名和对应方法
})
// 剪切板插件
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
// 注册全局组件
import '@/components/common/index.js'
// 注册全局api
import api from '@/api';
Vue.prototype.$api = api;

import * as accCalc from '@/utils/accCalc';
Vue.prototype.$accCalc = accCalc;

import DDIC from '@/utils/dataDictionary';
Vue.prototype.$DDIC = DDIC;

import * as util from '@/utils/index';
Vue.prototype.$util = util;
import CONFIG from '@/config'
Vue.prototype.CONFIG = CONFIG;
// 腾讯图形验证
Vue.prototype.$tencentCaptcha = (bc) => {
  let appid = process.env.VUE_APP_TENCENT_APPID
  // eslint-disable-next-line no-undef
  let captcha = new TencentCaptcha(appid, function (res) {
    if (res.ret === 0) {
      let { randstr, ticket } = res
      bc({ randStr: randstr, ticket })
    }
  })
  captcha.show()
}
Vue.use(ElementUI);
Vue.config.productionTip = false
let vueInstance = new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this//安装全局事件总线
  }
}).$mount('#app')
export default vueInstance