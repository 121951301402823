<template>
  <el-dialog class="protocol-dialog" title="" @close="$emit('showChange', false)" :visible.sync="dialogShow" width="60%" top="2vh">
    <el-scrollbar>
      <Agreement v-if="show=='agreement'" />
      <Application v-if="show=='application'" />
      <Privacy v-if="show=='privacy'" />
      <networkIdentity v-if="show=='networkIdentity'" />
    </el-scrollbar>

  </el-dialog>

</template>

<script>
import Agreement from './view/agreement.vue'
import Application from './view/application.vue'
import Privacy from './view/privacy.vue'
import networkIdentity from './view/networkIdentity.vue';
export default {
  components: { Agreement, Application, Privacy, networkIdentity },
  model: {
    prop: 'show',
    event: 'showChange'
  },
  props: ['show', 'type'],
  data () {
    return {
      dialogShow: false
    }
  },
  watch: {
    show () {
      this.dialogShow = this.show ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.protocol-dialog {
  .el-dialog__body {
    padding: 24px 0px;
    padding-bottom: 60px;
    .el-scrollbar {
      height: 80vh;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
      .el-scrollbar__view {
        padding: 0 48px;
      }
      h1 {
        font-size: 30px;
        color: #2a2b2c;
        text-align: center;
        line-height: 30px;
        padding-bottom: 35px;
      }
      p,
      li {
        font-size: 14px;
        color: #727887;
        line-height: 28px;
        text-indent: 2em;
      }
      h2 {
        font-size: 16px;
        color: #727887;
        padding-top: 20px;
        line-height: 24px;
      }
    }
  }
}
</style>