export default {
  path: '/portal',
  component: () => import('@/views/portal'),
  children: [
    // {
    //   path: '/home',
    //   name: 'home',
    //   // redirect: '/home',
    //   meta: {
    //     title: '首页',
    //     content: {
    //       keywords: '豸信CA、法大大CA、云海CA、山东豸信、豸信、ZXCA、zxca',
    //       description: '山东豸信CA，国内领先的电子认证服务和信息安全解决方案提供商'
    //     }
    //   },
    //   component: () => import('@/views/portal/home'),
    // },
    {
      path: '/serviceHall',
      name: 'serviceHall',
      meta: {
        content: {
          keywords: '数字证书、Ukey、电子认证、豸信认证、信息安全、电子签章、电子签署、电子签名、签名验签、时间戳、手机盾、pdf签署、签章客户端、证书助手、云证书',
          description: ''
        }
      },
      component: () => import('@/views/portal/serviceHall'),
    }, {
      path: '/aboutUs',
      name: 'aboutUs',
      meta: {
        content: {
          keywords: '豸信CA、法大大CA、云海CA、山东豸信、豸信、ZXCA、zxca',
          description: '山东豸信CA，国内领先的电子认证服务和信息安全解决方案提供商'
        }
      },
      component: () => import('@/views/portal/aboutZx/aboutUs'),
    }, {
      path: '/pressCenter',
      name: 'pressCenter',
      meta: {
        content: {
          keywords: '豸信CA、法大大CA、云海CA、山东豸信、豸信、ZXCA、zxca',
          description: '山东豸信CA，国内领先的电子认证服务和信息安全解决方案提供商'
        }
      },
      component: () => import('@/views/portal/aboutZx/pressCenter'),
    }, {
      path: '/pressCenter/:id',
      name: 'pressCenter',
      meta: {
        content: {
          keywords: '豸信CA、法大大CA、云海CA、山东豸信、豸信、ZXCA、zxca',
          description: '山东豸信CA，国内领先的电子认证服务和信息安全解决方案提供商'
        }
      },
      component: () => import('@/views/portal/aboutZx/pressCenter'),
    },
    {
      path: '/pressDetails',
      name: 'pressDetails',
      meta: {
        content: {
          keywords: '豸信CA、法大大CA、云海CA、山东豸信、豸信、ZXCA、zxca',
          description: ''
        }
      },
      component: () => import('@/views/portal/aboutZx/pressDetails'),
    },
    {
      path: '/downloadCenter',
      name: 'downloadCenter',
      component: () => import('@/views/portal/helpAndDownload/download'),
    },
    {
      path: '/helpCenter',
      name: 'helpCenter',
      meta: {
        content: {
          keywords: '数字证书、Ukey、电子认证、豸信认证、信息安全、电子签章、电子签署、电子签名、签名验签、时间戳、手机盾、pdf签署、签章客户端、证书助手、云证书',
          description: ''
        }
      },
      component: () => import('@/views/portal/helpAndDownload/helpCenter'),
    }, {
      path: '/helpList',
      name: 'helpList',
      component: () => import('@/views/portal/helpAndDownload/helpList'),
    }, {
      path: '/downloadList',
      name: 'downloadList',
      component: () => import('@/views/portal/helpAndDownload/downloadList'),
    }, {
      path: '/product-orgCert',
      name: 'product-orgCert',
      meta: {
        content: {
          keywords: '数字证书、Ukey、信息安全、电子签章、电子签名、云证书',
          description: ''
        }
      },
      component: () => import('@/views/portal/productCenter/orgCert'),
    }
    , {
      path: '/product-personCert',
      name: 'product-personCert',
      meta: {
        content: {
          keywords: '数字证书、Ukey、信息安全、电子签章、电子签名、云证书',
          description: ''
        }
      },
      component: () => import('@/views/portal/productCenter/personCert'),
    }, {
      path: '/product-sealBox',
      name: 'product-sealBox',
      component: () => import('@/views/portal/productCenter/sealBox'),
    }, {
      path: '/product-eSeal',
      name: 'product-eSeal',
      component: () => import('@/views/portal/productCenter/eSeal'),
    }, {
      path: '/product-verifySign',
      name: 'product-verifySign',
      component: () => import('@/views/portal/productCenter/verifySign'),
    }, {
      path: '/product-timestamp',
      name: 'product-timestamp',
      component: () => import('@/views/portal/productCenter/timestamp'),
    }, {
      path: '/product-mobileShield',
      name: 'product-mobileShield',
      component: () => import('@/views/portal/productCenter/mobileShield'),
    }, {
      path: '/product-electronicSignature',
      name: 'product-electronicSignature',
      component: () => import('@/views/portal/productCenter/electronicSignature'),
    }, {
      path: '/product-instructions',
      name: 'product-instructions',
      component: () => import('@/views/portal/productCenter/instructions'),
    }, {
      path: '/businessCooperation',
      name: 'businessCooperation',
      component: () => import('./../../views/portal/businessCooperation/cooperation.vue'),
    }]
}