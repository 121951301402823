/**
 * 隐藏数字串
 * @param val {Number, String} 转换的字符串对象
 * @param before  {Number} 前面保留位数
 * @param behind {Number}后面保留位数
 * @return {String}
 */
export function privateNumber(val, before = 4, behind = 3) {
    if (String(val).length < before + behind) return val;

    let number = String(val)
    let length = number.length;
    let digit = length - before - behind
    let reg = new RegExp(`^(\\d{${before}})\\d{${digit}}(\\d{${behind}})$`)
    return number.replace(reg, `$1${'*'.repeat(digit)}$2`)
}