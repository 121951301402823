// 引入vue
import Vue from 'vue'
// 引入同目录下的全部组件
const requireCom = require.context('.', true, /\.vue$/)
// 依次进行注册
requireCom.keys().forEach(key => {
  // console.log(key)
  // 字符串首字母大写处理
  function strUp (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  // 获取单个组件内容
  const _component = requireCom(key)
  // 获取组件名称
  const _componentName = strUp(key.replace(/^\.\//, '').replace(/\.\w+$/, ''))

  // 注册在vue上
  Vue.component(_componentName, _component.default || _component)
})
// 引入外部js组件
Vue.component('remote-script', {
  render: function (createElement) {
    var self = this;
    return createElement('script', {
      attrs: {
        type: 'text/javascript',
        src: this.src
      },
      on: {
        load: function (event) {
          self.$emit('load', event);
        },
        error: function (event) {
          self.$emit('error', event);
        },
        readystatechange: function (event) {
          if (this.readyState == 'complete') {
            self.$emit('load', event);
          }
        }
      }
    });
  },
  props: {
    src: {
      type: String,
      required: true
    }
  }
});