// import Vue from 'vue'
// import API from '@/api';
export function isEmptyObj (object) {
  let item
  for (item in object) {
    return false
  }
  return true
}
export function downloadById (id) {
  // API.getDownloadcontentInfo({ id }).then(res => {
  //   API.getDownloadcontentFile({
  //     fileId: res.data.fileId,
  //     version: res.data.version
  //   })
  //     .then(res => {
  let link = document.createElement('a')
  let body = document.querySelector('body')
  link.href = id
  // link.download = res.data.filename
  // fix Firefox
  link.style.display = 'none'
  body.appendChild(link)
  link.click()
  body.removeChild(link)
  window.URL.revokeObjectURL(link.href)
  //     })
  // })
}

export function downloadFile (url, filename, type = 'file') {
  if (type == 'file') {
    saveAs(url, filename)
  } else if (type == 'base64') {
    let blob = base64ToBlob(url);
    saveAs(blob, filename)
  }
  function base64ToBlob (base64) {
    let parts = base64.split(';base64,');
    let contentType = parts[0].split(':')[1];
    let raw = window.atob(parts[1]);
    let rawLength = raw.length;

    let uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {
      type: contentType
    });
  }

  function saveAs (blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename)
    } else {
      let link = document.createElement('a')
      let body = document.querySelector('body')

      link.href = window.URL.createObjectURL(blob)
      link.download = filename

      // fix Firefox
      link.style.display = 'none'
      body.appendChild(link)

      link.click()
      body.removeChild(link)

      window.URL.revokeObjectURL(link.href)
    }
  }
}
export function telSecret (str) {
  if (str && str.length > 10) {
    str = str.substr(0, 3) + '****' + str.substr(-4, 4)
  }
  return str
}
export function IdNoSecret (str) {
  if (str && str.length > 10) {
    str = str.substr(0, 6) + '*********' + str.substr(-3, 3)
  }
  return str
}