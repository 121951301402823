<template>
  <div id="app">
    <router-view></router-view>
    <Login />
    <Certification />
  </div>
</template>
<script>
import Login from '@/components/Login.vue'
import Certification from '@/components/Certification.vue'
export default {
  name: 'Home',
  components: { Login, Certification },
  data () {
    return {}
  },
  created () {
    // 判断用户是哪种设备
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      switch (process.env.VUE_APP_ENV) {
        case 'dev':
          window.location.replace("https://portal-mweb-dev.zxca.cn/");
          break;
        case 'test':
          window.location.replace("https://portal-mweb-test.zxca.cn/");
          break;
        case 'prod':
          window.location.replace("https://m.zxca.cn/");
          break;
        case 'pre':
          window.location.replace("https://m.zxca.cn/");
          break;
        case 'dist':
          window.location.replace("https://m.zxca.cn/");
          break;
        default:
          break;
      }
    }

    //在页面加载时读取localStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
    }

    //在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state))
    })

    // 禁用浏览器缩放
    window.addEventListener('mousewheel', function (event) {
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault();
      }
    }, { passive: false });
    //firefox
    window.addEventListener('DOMMouseScroll', function (event) {
      if (event.ctrlKey === true || event.metaKey) {
        event.preventDefault();
      }
    }, { passive: false });
  },
  methods: {},
  watch: {}
}
</script>
<style lang='scss'>
</style>
