export default {
  path: '/userInfo',
  component: () => import('@/views/userInfo/'),
  redirect: '/userInfo/index.vue',
  meta: {
    scrollToTop: true
  },
  children: [
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/userInfo/account/account.vue'),
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      component: () => import('@/views/userInfo/enterprise/enterprise.vue'),
    }
  ]
}