import request from '@/utils/request'
// ocsp查询

export function koalQryByCertSn (params) {
  return request({
    url: '/portal/cert/koal_qry_by_cert_sn',
    method: 'get',
    params
  })
}

// 获取项目列表
export function getProjectList (params) {
  return request({
    url: '/portal/transaction/list_project',
    method: 'get',
    params
  })
}
// 获取证书类型列表
export function getCertTemplates (params) {
  return request({
    url: '/portal/transaction/get_cert_templates',
    method: 'get',
    params
  })
}
// 证书新办业务提交

export function applySubmit (data) {
  return request({
    url: '/portal/transaction/apply_submit',
    method: 'post',
    data,
    skipErr: true
  })
}
// 查看业务单详情
export function getTransactionDetail (params) {
  return request({
    url: '/portal/transaction/detail',
    method: 'get',
    params,
    skipErr: true
  })
}
// 查看业务单详情
export function getTransactionOrDetail (params) {
  return request({
    url: '/portal/transaction/detail-original',
    method: 'get',
    params
  })
}
// 取消业务单
export function cancelTransaction (params) {
  return request({
    url: '/portal/transaction/cancel',
    method: 'post',
    params
  })
}
// 根据业务单id获取证书申请表pdf
export function getCertApplyForm (params) {
  return request({
    url: '/portal/transaction/get_cert_apply_form',
    method: 'get',
    responseType: 'blob',
    params
  })
}
// 保存证书申请表或邮寄信息
export function saveApplyFileInfo (data) {
  return request({
    url: '/portal/transaction/save-apply-file-info',
    method: 'post',
    data
  })
}
// 获取付款二维码
export function generatePayQrcode (params) {
  return request({
    url: '/portal/transaction/generate_pay_qrcode',
    method: 'post',
    responseType: 'blob',
    params
  })
}
// 获取付款结果
export function getTransIsPay (params) {
  return request({
    url: '/portal/transaction/get_trans_is_pay',
    method: 'get',
    params,
    skipErr: true,
  })
}
// 根据证书序列号，查询当前业务节点
export function getTransNode (params) {
  return request({
    url: '/portal/transaction/get_trans_node',
    method: 'get',
    params
  })
}
// 根据证书sn查询证书信息

export function getCertBySn (params) {
  return request({
    url: '/portal/transaction/get_cert_by_sn',
    method: 'get',
    params,
    skipErr: true,
  })
}
// 根据申请表ID查询新生成的证书序列号

export function getNewCertSn (params) {
  return request({
    url: '/portal/transaction/get_new_cert_sn',
    method: 'get',
    params
  })
}
// 证书操作类业务单提交
export function operationSubmit (data) {
  return request({
    url: '/portal/transaction/operation-submit',
    method: 'post',
    data,
    skipErr: true,
  })
}
// 获取续期后新证书信息
export function getPostponeCert (data) {
  return request({
    url: '/portal/transaction/get_postpone_cert',
    method: 'post',
    timeout: 10000,
    data
  })
}
// 发送解锁验证码
export function getUnlockVerCode (params) {
  return request({
    url: '/portal/transaction/get_unlock_ver_code',
    method: 'get',
    params
  })
}
// 证书查询
export function puhuaQryCert (params) {
  return request({
    url: '/portal/cert/qry_with_event_cert',
    method: 'get',
    params
  })
}
// 文档验证
export function pdfVerify (data) {
  return request({
    url: '/portal/signature/pdf_verify',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data;' },
    data,
    skipErr: true,
  })
}
//

export function getCertDn (params) {
  return request({
    url: '/portal/transaction/cert/process/get-cert-dn',
    method: 'get',
    params
  })
}
// 获取待补办证书列表
export function getReplaceCertList (params) {
  return request({
    url: '/portal/transaction/list-replace-cert',
    method: 'get',
    params
  })
}
// 移动端支付
export function getPayInfo (params) {
  return request({
    url: '/portal/api/trans/pay-info',
    method: 'get',
    params
  })
}
// 移动端支付跳转微信支付
export function getPayH5 (params) {
  return request({
    url: '/portal/api/trans/pay-h5',
    method: 'post',
    params
  })
}

// 获取已生成的证书（续期、变更）
export function getRemakeCert (data) {
  return request({
    url: '/portal/transaction/issue-remake',
    method: 'post',
    timeout: 10000,
    data
  })
}


// 在线出证业务单提交 （forceSubmit字段确定是否在无豸信证书的时候强制提交业务单申请）
export function reportSubmit (params) {
  return request({
    url: '/portal/report/trans-submit',
    method: 'post',
    params,
    skipErr: true,
  })
}
// 查询在线出证业务单详情
export function getReportDetail (params) {
  return request({
    url: '/portal/report/trans-detail',
    method: 'get',
    params
  })
}
// 查询出证状态
export function getReportResult (params) {
  return request({
    url: '/portal/report/report-result',
    method: 'get',
    params,
    skipErr: true,
  })
}
// 条件分页查询在线出证业务单列表
export function getReportPage (params) {
  return request({
    url: '/portal/report/page',
    method: 'get',
    params
  })
}
// 在线出证业务单取消
export function reportCancel (params) {
  return request({
    url: '/portal/report/trans-cancel',
    method: 'post',
    params
  })
}
// 获取出证微信付款二维码
export function getReportPayQrcode (params) {
  return request({
    url: '/portal/report/pay/pay-qrcode',
    method: 'post',
    params
  })
}
// 查询是否支付完成
export function getIsPay (params) {
  return request({
    url: '/portal/report/pay/get-trans-is-pay',
    method: 'get',
    params,
    skipErr: true,
  })
}

// 分页查询已开票/未开票列表
export function getReportInvoicePage (params) {
  return request({
    url: '/portal/report/invoice/page',
    method: 'get',
    params
  })
}
// 获取开票二维码
export function getReportInvoicQrcode (params) {
  return request({
    url: '/portal/report/invoice/get_invoice_qr_code',
    method: 'post',
    params
  })
}
// 在线变更、续期URL校验
export function onlineCheckCertUpdatePage (params) {
  return request({
    url: '/partner/epoint/online/check-cert-update-page',
    method: 'get',
    params
  })
}
// 在线变更详情
export function onlineCheckCertUpdateInfo (data) {
  return request({
    url: '/partner/epoint/online/update-info',
    method: 'post',
    data
  })
}
// 在线续期详情
export function onlineCheckCertPostponeInfo (data) {
  return request({
    url: '/partner/epoint/online/postpone-info',
    method: 'post',
    data
  })
}
// 在线变更提交
export function onlineCheckCertUpdate (data) {
  return request({
    url: '/partner/epoint/online/update',
    method: 'post',
    data
  })
}
// 在线续期提交
export function onlineCheckCertPostpone (data) {
  return request({
    url: '/partner/epoint/online/postpone',
    method: 'post',
    data
  })
}

// 获取证书DN
export function onlineCertDn (params) {
  return request({
    url: '/partner/epoint/online/cert/process/get-cert-dn',
    method: 'get',
    params
  })
}
// 在线出证业务单比对是否下证完成
export function onlineCheckCertFinish (data) {
  return request({
    url: '/partner/epoint/online/finish',
    method: 'post',
    data
  })
}
// 付款二维码以及业务的信息
export function getTranspayQrCode (params) {
  return request({
    url: '/portal/api/trans/pay-qrCode',
    method: 'get',
    params,
    skipErr: true,
  })
}
// 查询支付是否完成
export function getTranIsPay (params) {
  return request({
    url: '/portal/api/trans/get_trans_is_pay',
    method: 'get',
    params,
    skipErr: true,
  })
}