<template>
  <div class="userAgreement">
    <h1>豸信CA用户注册与使用协议</h1>
    <p>本网站由山东豸信认证服务有限公司（下称“豸信CA”）负责运营。本协议双方为豸信CA与豸信CA注册用户（下称“用户”或“您”），本协议具有合同效力，适用于您在本网站的全部活动。本协议更新于2019年10月23日。</p>
    <h2>一、注册声明</h2>
    <h2>二、定义及解释</h2>
    <h2>三、豸信CA服务简介</h2>
    <h2>四、服务费用</h2>
    <h2>五、用户账户</h2>
    <h2>六、通知与送达</h2>
    <h2>七、使用事项</h2>
    <h2>八、用户间纠纷处理</h2>
    <h2>九、隐私条款</h2>
    <h2>十、知识产权声明</h2>
    <h2>十一、免责声明</h2>
    <h2>十二、违约责任</h2>
    <h2 style="margin-bottom:24px;">十三、法律适用与管辖</h2>
    <h2>一、注册声明</h2>
    <p>（一）请您在注册成为豸信CA用户前务必仔细阅读本协议，若您不同意本协议的任意内容，或者无法准确理解豸信CA对条款的解释，请不要进行后续操作；若您注册成为豸信CA用户，则表示您对本协议的全部内容已充分阅读并认可和同意遵守。同时，承诺遵守中国法律、法规、规章及其他政府规范性文件的规定，如有违反而造成任何法律后果，您将以本人名义独立承担所有相应的法律责任。</p>
    <p>
      （二）本协议内容包括以下条款及已经发布的或将来可能发布的各类规则，如《山东豸信认证服务有限公司电子认证业务规则》。所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。豸信CA有权根据需要不定时地制定、修改本协议或各类规则，如本协议及规则有任何变更，一切变更以本网站最新公布的内容为准。经修订的协议、规则一经公布，立即自动生效，对新协议、规则生效之后注册的用户发生法律效力。对于协议、规则生效之前注册的用户，若用户在新规则生效后继续使用本网站提供的各项服务，则表明用户已充分阅读并认可和同意遵守新的协议或规则。若您拒绝接受新的协议和规则，您必须放弃使用豸信CA提供的各项服务（包括但不限于使用用户名和密码登录豸信CA、在豸信CA上进行数字证书相关业务申请和办理等）。
    </p>
    <p>（三）您使用豸信CA即表示您同意豸信CA将您的个人身份信息或企业信息、其个人或企业的数字证书认证信息传输至公司合作的第三方认证机构以及司法鉴定机构，且前述信息保存至数字证书失效后5年，且该等信息不因您的账户锁定或注销而停止保存。</p>
    <h2>二、定义及解释</h2>
    <p>（一）豸信CA账户</p>
    <p>指您通过注册获得豸信CA账号以供您使用豸信CA各项服务的账户。</p>
    <p>（二）本网站</p>
    <p>除本协议另有规定外，指<a href="https://www.zxca.cn">www.zxca.cn</a>、www.sdmyca.com、www.sdmyca.cn。</p>
    <p>（三）数字证书</p>
    <p>指由豸信CA签发的，含有签发数字证书的电子认证服务机构名称、证书持有人名称、证书序列号、证书有效期、证书持有人的数字证书验证数据、电子认证服务机构的数字证书、工业和信息化部规定的其他内容的身份证明数据。</p>
    <p>（四）数据电文</p>
    <p>指以电子、光学、磁或者类似手段生成、发送、接收或者储存的信息。</p>
    <h2>三、豸信CA服务简介</h2>
    <p>（一）证书查询</p>
    <p>用户可输入数字证书基本信息，进行数字证书有效性查询</p>
    <p>（二）证书申请</p>
    <p>用户首次申请证书，用户可以根据需要选择具体项目，如实填写相关的信息、邮寄地址，通过线上的方式申请数字证书</p>
    <p>（三）证书续期</p>
    <p>用户证书即将过期时，可在线申请续期，用户需在证书有效期内申请</p>
    <p>（四）证书注销</p>
    <p>当用户的数字证书私钥泄密、Key丢失、Key损坏时用户可以在线申请注销数字证书</p>
    <p>（五）介质解锁</p>
    <p>用户介质锁定后，可在线申请解锁</p>
    <p>（六）业务单查询</p>
    <p>用户可以通过业务单号、业务类型查询业务单状态</p>
    <h2>四、服务费用</h2>
    <p>（一）当用户使用本网站服务时，豸信CA将向用户收取相应服务费用。各项服务具体费用标准请详见网站上公布的业务定价及有关规则。用户承诺按照豸信CA公布的服务费用标准向豸信CA支付服务费用。豸信CA保留单方面制定与调整服务费用的权利。费用标准一旦被修改，即公布在本网站上，修改后的费用标准一经公布即生效，适用于费用标准修改后发生的交易。</p>
    <p>（二）豸信CA在避免用户损失的前提下，有权单方决定暂时或永久地改变或停止提供某些服务。</p>
    <h2>五、用户账户</h2>
    <p>（一）豸信CA用户</p>
    <p>
      豸信CA的用户包括企业用户和个人用户。企业用户是指通过本网站完成全部注册程序后，使用豸信CA提供的服务，并且依据中华人民共和国法律在境内（香港、台湾、澳门除外）设立的，能够独立承担民事责任的法人和其他组织。个人用户是指中华人民共和国境内（香港、台湾、澳门除外）的公民，通过本网站完成全部注册程序后，使用豸信CA提供的服务，并且符合中华人民共和国法律规定的年满18周岁，具有完全民事行为能力，能够独立承担民事责任的自然人。
    </p>
    <p>（二）用户注册与实名认证</p>
    <p>1.个人及企业在本网站注册方可成为豸信CA的用户。个人及企业注册成为豸信CA用户后，申请数字证书时须进行实名认证。在注册与实名认证过程中个人及企业必须根据本网站的要求提供最新、真实、有效及完整的资料，具体详见《山东豸信认证服务有限公司电子认证业务规则》。</p>
    <p>2.实名认证具体操作流程请严格按照本网站实名认证规定的流程履行，否则将影响实名审核的结果。</p>
    <p>3.用户同意并授权豸信CA审核、核对用户资料信息以确认用户身份，但用户信息是由用户本人自行提供的，故豸信CA无法保证该信息之准确、有效和完整。所提交的用户认证信息将用于核发其专属的电子签名文件，豸信CA将对用户的信息严格保密。隐私保护请详见《隐私声明》。</p>
    <p>（三）账户安全</p>
    <p>
      1.豸信CA将通过用户的登录名、密码以及登陆后的其他操作识别用户的相关指示，同时豸信CA会通过本服务应用识别来自用户使用的各类产品或设备的指示，用户应当妥善保管豸信CA登录名、密码、身份信息，各类产品或设备，对于因用户泄露登录名、密码、身份信息或是因产品或设备遗失导致的损失，由用户自行承担。用户应在登录本系统时段结束时，以正确步骤离开网站。用户如果发现有他人冒用或盗用其豸信CA登录名及密码或任何其他未经合法授权之情形，或发生与豸信CA账户关联的手机或其他设备遗失或其他可能危及到豸信CA账户安全情形时，应立即以有效方式通知本公司，向本公司申请账户锁定。
    </p>
    <p>2.用户应对其持有的豸信CA账户负责，只有用户本人方可使用该账户。该账户不可转让、不可继承，在您决定不再使用该账户时，您应将该账户内的数据导出或删除，并按本公司规定流程向本公司申请锁定该账户。</p>
    <p>3.豸信CA可以暂时停止提供或者限制本服务部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要用户仍然使用本服务，即表示同意本协议或者变更后的协议。</p>
    <p>4.豸信CA对因用户未能遵守本协议的约定而发生的任何直接或间接损失等不利后果不承担任何责任。</p>
    <p>5.豸信CA有权了解您使用本公司产品或服务的真实交易背景及目的，用户应如实提供本公司所需的真实、全面、准确的信息。如果本公司有合理理由怀疑用户提供虚假信息、进行欺诈等违法违规行为的，本公司有权根据相关国家法律法规的规定对用户个人信息以及签署的文件信息等进行核实、查询、披露，有权锁定相应账户并配合相关机关进行后续调查。</p>
    <p>（四）账户锁定</p>
    <p>1.用户登录豸信CA账户，若密码连续5次输入错误，豸信CA将临时锁定账户，持续时间为15分钟。在此期间，用户将无法使用豸信CA的任何服务。</p>
    <p>2.用户在需要停止本协议约定的服务时，可申请锁定豸信CA账户。用户申请锁定的豸信CA账户应当是依照本协议的约定注册并由豸信CA提供给用户本人的账户，用户应当依照豸信CA的规定程序申请账户锁定。</p>
    <p>3.用户申请锁定的豸信CA账户应当处于正常状态，不存在任何可能由于该账户锁定而被影响的合同关系或其他权利义务关系，以及本公司认为锁定账户会由此产生未了结的权利义务而产生纠纷的情况。如申请锁定的账户有关联账户或子账户的，在该关联账户或子账户被锁定前，该账户不能申请锁定。</p>
    <p>4.如果用户在使用本系统相关服务时有涉及违法违规行为的，豸信CA有权锁定您名下的全部或部分账户。</p>
    <p>（五）账户注销</p>
    <p>1.在您需要终止使用我们的服务时，您可以申请注销您的账户，您可以联系我们的人工客服（电话：400-040-2013或400-030-2013），申请注销您的账户。</p>
    <p>我们在此善意地提醒您，您注销账户的行为会使您无法继续使用豸信CA的相关服务，包括数字证书相关业务申请和办理的服务。注销账户后您的个人信息会保持不可被检索、访问的状态，我们将不会再使用或对外提供与该账户相关的个人信息，但您在使用豸信CA服务期间提供或产生的信息我们仍需按照监管要求的保存5年以上，且在保存的时间内依法配合有权机关的查询。</p>
    <h2>六、通知与送达</h2>
    <p>（一）通知方式</p>
    <p>本协议条款及任何其他的协议、公告或其他关于您使用本服务账号及服务的通知，您同意本网站使用电子方式通知您。电子方式包括但不限于以电子邮件方式、或于本网站或者合作网站上公布、或无线通讯装置通知等方式。</p>
    <p>（二）送达</p>
    <p>
      网站的通知如以公示方式做出，一经在本网站公示即视为已经送达。除此之外，其他向您个人发布的具有专属性的通知将由本网站在您注册时或者注册后变更用户信息时向本网站提供的电子邮箱或用户注册后在本网站绑定的手机号码发送，一经发送即视为已经送达。请您密切关注用户的电子邮箱以及手机中的短信信息。因信息传输或您变更邮箱但未在本网站的注册信息中填写您变更后的邮箱等原因导致您未在前述通知发出当日收到该等通知的，本网站不承担责任。
    </p>
    <p>（三）通知内容</p>
    <p>您同意豸信CA利用在本网站登记的联系方式与您联络并向您传递相关的信息，包括但不限于行政管理方面的通知、产品信息、有关您使用本网站的通讯以及针对性的广告等。</p>
    <h2>七、使用事项</h2>
    <p>
      （一）您在使用豸信CA服务时，必须遵守国家各项法律、法规、规章以及政府规范性文件，并接受豸信CA相关的协议、规则、规定、程序和惯例的约束。禁止在本网站进行任何可能违反国家法律、法规、规章和政府规范性文件的行为（包括但不限于侵犯任何第三方著作权、专利权、商标权、商业秘密、隐私权、名誉权或其它权利的行为）或者任何未经授权使用本网站的行为（包括但不限于擅自进入本网站未公开的系统、不正当的使用密码和网站的任何内容等）。
    </p>
    <p>
      （二）注册成功后，每位用户都拥有一个属于自己的用户名和登录密码，通过使用该用户名和登录密码您可以登录豸信CA，从而使用豸信CA服务。您须妥善保管您在豸信CA上的用户名和密码。本网站通过您的用户名、密码以及登录后的操作来识别您的指令。您确认，使用您的用户名和密码登录本网站后在本网站的一切行为均代表您本人意志。使用您的用户名和密码登录操作所产生的电子信息记录均为您行为的有效凭据，并由您本人承担相应的法律后果。不得在未经豸信CA许可的情况下出售或授权使用账号。
    </p>
    <p>（三）您对通过豸信CA发布的所有信息（内容包括但不限于个人信息、商业信息，形式包括但不限于文字、图片）的真实性、准确性、即时性、完整性和合法性独立承担所有责任。同时，在您所发布的信息中不得含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序。</p>
    <p>（四）本网站内容可能涉及由第三方所有、控制或运营的其它网站（以下称“第三方网站”）。本网站不能保证也没有义务保证第三方网站上的信息的真实性和有效性。您应按照第三方网站的相关协议与规则使用第三方网站，而不是按照本协议。第三方网站的内容、产品、广告和其他任何信息均由您自行判断并承担风险，而与本网站无关。</p>
    <h2>八、用户间纠纷处理</h2>
    <p>（一）在使用豸信CA服务的过程中，用户发现任何可能侵害自己或豸信CA权利的事实时，应及时通知豸信CA并提供相应的证明材料。因投诉不实给豸信CA或第三方造成损失的，用户应承担法律责任。</p>
    <p>
      （二）豸信CA有权接受并处理您与其他用户间因使用豸信CA服务而产生的纠纷及投诉，有权通过电话、短信、电子邮件等联系方式向您了解情况，并将所了解的情况通过上述方式通知对方。您有义务在收到豸信CA通知后，在指定的时间内提供相应的资料，以配合纠纷及投诉的情况了解与处理，否则，豸信CA有权单方面独立判断其他用户对您的投诉，一旦成立，并做出对您不利的处理结果。同时，豸信CA有权立即中止或终止您的相关豸信CA服务。
    </p>
    <p>（三）豸信CA受理、处理纠纷、争议及投诉完全依据您的授权。因豸信CA对证据的鉴别能力及对纠纷的处理能力有限，不保证处理结果一定符合您的期望。豸信CA有权决定是否参与争议的处理。</p>
    <p>（四）经生效法律文书确认用户存在违法或违反本协议行为或者豸信CA自行判断您涉嫌存在违法或违反本协议行为的，豸信CA有权公布用户的违法行为并做出相应处理，包括但不限于终止服务、永久禁止使用豸信CA等。</p>
    <h2>九、隐私条款</h2>
    <p>请您详见隐私声明。</p>
    <h2>十、知识产权声明</h2>
    <p>
      无论是否明示，豸信CA对网站内所有非公有领域或非他方专有的信息内容享有知识产权（包括但不限于商标权、专利权、著作权），信息内容包括但不限于文字、图片、软件、音频、视频、数据、源代码、设计。非经豸信CA书面授权同意，任何组织或个人都不得复制、打印和传播属于豸信CA的信息内容用于其他用途。网站所有的产品、技术及程序均属于豸信CA知识产权，未经豸信CA书面授权许可，任何人不得擅自使用（包括但不限于以非法的方式打印、复制、传播、展示、下载等）。否则，豸信CA将依法追究其法律责任。
    </p>
    <h2>十一、免责声明</h2>
    <p>
      （一）您有义务在注册时提供自己的真实、最新、完整的资料，并保证电子邮件地址、联系电话、联系地址、邮政编码等内容的最新、有效性及安全性。您有义务维护并立即更新您的个人资料，确保其真实、最新、有效及完整。若您提供任何错误、虚假、过时或不完整的资料，或者本网站有合理的理由怀疑您提供的为错误、虚假、过时或不完整的资料时，本网站有权暂停或终止您的账号，并拒绝您使用本网站部份或全部服务。在此情况下，本网站不承担任何责任，并且您同意自行承担因此所产生的直接或间接的任何支出或损失。
    </p>
    <p>（二）在如下情况，豸信CA有权中止、终止对您提供部分或全部服务而不承担任何责任：</p>
    <ul>
      <li>1.在豸信CA未向您收费的情况下，豸信CA可自行全权决定以合理理由 (包括但不限于您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事) 终止您的登录密码、用户名 (或其任何部份) 或您对服务的使用。</li>
      <li>
        2.在出现下列任一情况时，豸信CA可立即发出警告，中止或终止您的账号，删除您的任何现有信息，以及您在网站上展示的任何其他资料：（a）您违反本协议；(b)豸信CA无法核实或鉴定您向豸信CA提供的任何资料；（c）豸信CA相信您的行为可能会使您、豸信CA其他用户或通过豸信CA提供服务的第三者服务供应商发生任何法律责任；(d)发现您从事涉及网站的诈骗等违法违规活动，豸信CA有权中止或终止您的服务。对于（a）、（b）、(c)三项，豸信CA不限制用户采取相关补救措施。
      </li>
      <li>3.对于本协议规定的采取的中止或终止措施，豸信CA不对结果承担任何责任。</li>
      <li>4.根据本协议的任何规定中止或终止您使用服务之措施，豸信CA将本着审慎的态度实施，并将履行告知义务，即使通知您不能继续使用豸信CA的服务，但在您的账号内的所有相关资料，豸信CA将继续为您保存，且可以查询和为您提供相关文件内容的证明。</li>
      <li>5.由于互联网本身所具有的不稳定性，豸信CA无法保证服务不会中断。系统因有关状况无法正常运作，使用户无法使用任何豸信CA服务或使用豸信CA服务受到任何影响时，豸信CA对用户或第三方不负任何责任，前述状况包括但不限于：</li>
      <ol>
        <li>（1）豸信CA系统停机维护期间。</li>
        <li>（2）电信设备出现故障不能进行数据传输的。</li>
        <li>（3）由于黑客攻击、网络供应商技术调整或故障、网站升级、银行方面的问题等原因而造成的豸信CA服务中断或延迟。</li>
        <li>（4）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成豸信CA系统障碍不能执行业务的。</li>
      </ol>
      <li>6.因用户的过错导致的任何损失，该过错包括但不限于：操作不当、遗忘或泄露密码、密码被他人破解、用户使用的计算机系统被第三方侵入、用户委托他人代理时他人恶意或不当操作而造成的损失，豸信CA不承担任何责任。</li>
    </ul>
    <h2>十二、违约责任</h2>
    <p>（一）因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律、法规、规章、政府规范性文件或侵害了第三方的权利，而使第三方对豸信CA及其股东、职员、代理人提出索赔要求（包括司法费用和其他专业人士的费用），您必须对豸信CA及股东 、职员、代理人承担赔偿责任，使其等免遭损失。 、职员、代理人承担赔偿责任，使其等免遭损失。</p>
    <p>（二）如因豸信CA违反有关法律、法规或本协议项下的任何条款而给用户造成损失，豸信CA同意承担由此造成的损害赔偿责任。</p>
    <h2>十三、法律适用与管辖</h2>
    <p>（一）本协议的订立、变更、执行和解释，以及与本协议有关的争议解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项没有法律规定或规定不明确，则应参照通用的国际商业惯例和行业惯例。</p>
    <p>（二）如因本协议或豸信CA服务所引起或与其有关的任何争议，争议方均可提请深圳国际仲裁院按照该会有效的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均具有约束力。</p>
    <p>十四、其他</p>
    <p>（一）本协议自您同意勾选并成功注册为本网站用户之日起生效，除非本网站终止本服务协议或者用户丧失本网站用户资格，否则本服务协议始终有效。本服务协议终止并不免除用户根据本服务协议或其他有关协议、规则所应承担的义务和责任。</p>
    <p>（二）本服务协议部分条款被认定为无效时，不影响本服务协议其他条款的效力。</p>
    <p>（三）本协议中的标题仅为方便而设，在解释本协议时应被忽略。</p>
    <p>（四）豸信CA对本服务协议享有最终的解释权。</p>
  </div>
</template>

<script>
export default {
  props: ['showAgreement'],
  methods: {
    closeAgreement () {
      this.$emit('closeAgreement', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
