import index from '@/views/protocol/index'
import privacy from '@/views/protocol/view/privacy.vue'
import agreement from '@/views/protocol/view/agreement.vue'
import application from '@/views/protocol/view/application.vue'
const ProtocolRouter =
{
  path: '/protocol',
  redirect: '/protocol/privacy',
  component: index,
  children: [
    {
      path: '/privacy',
      name: 'protocol-privacy',
      component: privacy,
    }, {
      path: '/register',
      name: 'protocol-register',
      component: agreement,
    },
    {
      name: '/certificate-application',
      path: 'protocol-certificate',
      component: application,
    }
  ]
}

export default ProtocolRouter
