export default {
  // 业务单类型 transType：
  transType: {
    '1': '印章新办',
    '2': '印章更新',
    '3': '印章续期',
    '4': '印章注销',
    '5': '印章挂失',
    '6': '印章解挂',
    '7': '印章解锁',
    '9': '印章补办',

  },
  // 印章状态 sealStatus：
  sealStatus: {
    '-2': {
      val: "已过期",
      color: '#F00808'
    },
    '-1': {
      val: "已注销",
      color: '#AEAFB0'
    },
    '0': {
      val: "已停用",
      color: '#ECB00A'
    },
    '1': {
      val: "正常",
      color: '#44A01C'
    },
  },
  // 印章类型 sealType：
  sealType: {
    '1': "个人章",
    '2': "法人单位章",
    '3': "职务章",
    '4': "部门章",
  },
  // 印章签名类型 signType
  signType: {
    'SM3WithSM2': "国密",
    'SHA256WithRSA': "通用",
  },
  // 个人证件号码 personIdentType
  personIdentType: {
    '0': "居民身份证",
    // '1': "护照",
    'Z': "其他",
  },
  // 机构证件类型 orgIdentType
  orgIdentType: {
    // '7': "组织机构代码证",
    // '8': "普通营业执照",
    'Q': "统一社会信用代码",
    // 'Z': "其他",
  },
  // 业务单状态 transStatus
  transStatus: {
    '1': {
      val: '已提交',
      color: '#44A01C'
    },
    '2': {
      val: '待审核',
      color: '#ECB00A'
    },
    '3': {
      val: '审核未通过',
      color: '#F00808'
    },
    '4': {
      val: '待付款',
      color: '#ECB00A'
    },
    '5': {
      val: '待收货',
      color: '#ECB00A'
    },
    '6': {
      val: '待续期更新',
      color: '#ECB00A'
    },
    '7': {
      val: '待介质解锁',
      color: '#ECB00A'
    },
    '8': {
      val: '待收货',
      color: '#ECB00A'
    },
    '9': {
      val: '待自取',
      color: '#ECB00A'
    },
    '10': {
      val: '待收货',
      color: '#ECB00A'
    },
    '11': {
      val: '已完成',
      color: '#44A01C'
    },
    '12': {
      val: '已取消',
      color: '#BBC0CE'
    },
    '13': {
      val: '已作废',
      color: '#BBC0CE'
    },
  },
  // 印章样式
  patternStyle: {
    '0': {
      img: require('./../assets/image/businessFor/nopattern.png'),
      name: '圆章'
    },
    '1': {
      img: require('./../assets/image/businessFor/ovalSeal.png'),
      name: '椭圆章'
    },
    // '2': {
    //   img: require('./../assets/image/businessFor/chenSeal.png'),
    //   name: '中英章'
    // }
  },
  patternStyle1: {
    '0': {
      img: require('./../assets/image/businessFor/square.png'),
      name: '正方形章'
    },
    '1': {
      img: require('./../assets/image/businessFor/rectangle.png'),
      name: '长方形章'
    }
  },
  // 圆形章尺寸
  patternStyleType1: {
    '0': {
      label: '38mm*38mm',
      value: '38*38'
    },
    '1': {
      label: '40mm*40mm',
      value: '40*40'
    },
    '2': {
      label: '42mm*42mm',
      value: '42*42'
    }
  },
  // 椭圆章尺寸
  patternStyleType2: {
    '0': {
      label: '40mm*30mm',
      value: '40*30'
    },
    '1': {
      label: '45mm*30mm',
      value: '45*30'
    }
  },
  // 正方形章尺寸
  patternStyleType3: {
    '0': {
      label: '18mm*18mm',
      value: '18*18'
    },
    '1': {
      label: '20mm*20mm',
      value: '20*20'
    },
    '2': {
      label: '38mm*38mm',
      value: '38*38'
    },
  },
  // 业务单状态 transProcessCode
  transProcessCode: ({
    code,
    auditResult,
    auditComment,
    expressName,
    expressNo
  }) => {
    switch (code) {
      case 'submit':
        return `您提交了申请单`
      case 'trial':
        return `您提交的申请已完成初审，请邮寄纸质申请资料至<span class='aaa'>山东豸信安全认证服务有限公司</span>（联系电话：<span>400-040-2013</span>，联系地址：<span>山东济南历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）</span > `
      case 'audit':
        return auditResult ? '您提交的申请已通过审核，请尽快完成付款' : `审核未通过，未通过原因:${auditComment}。如有问题请联系：400-040-2013`
      case 'make_key':
        return `您的印章已制作完成`
      case 'send_key':
        return `您的印章已寄出，交付${expressName}，运单号为${expressNo}`
      case 'cancel':
        return `您的印章申请已取消`;
      case 'payment':
        return `您提交的申请已付款，待分配制作`
      case 'obsolete':
        return `您的印章申请已作废`
      case 'finish':
        return `已完成`
      default:
        return ''
    }
  }
}