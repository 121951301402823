import request from '@/utils/request'
// 身份实名产品、云证书API产品控制器  
export function getIntegratedList (params) {
  return request({
    url: '/portal/product/integrated-list',
    method: 'GET',
    params
  })
}

// 查询云证书API产品所有接口列表
export function getCloudCertList (params) {
  return request({
    url: '/portal/product/cloud-cert/list',
    method: 'GET',
    params
  })
}

// 获取所有身份认证产品列表
export function getIndentityList (params) {
  return request({
    url: '/portal/product/indentity/list',
    method: 'GET',
    params
  })
}

// 获取所有身份认证产品列表
export function getByIdDetails (params) {
  return request({
    url: '/portal/product/get-detail',
    method: 'GET',
    params
  })
}


// 获取当前账户下所有满足条件的客户信息
export function getCustomerByCondition (params) {
  return request({
    url: '/portal/customer/authentication/customer-by-condition',
    method: 'GET',
    params
  })
}
// 提交订单
export function setOrderSubmit (data) {
  return request({
    url: '/portal/order/submit',
    method: 'POST',
    data
  })
}

// 根据id和订单类型获取订单详细信息
export function getOrderDetail (params) {
  return request({
    url: '/portal/order/get-detail',
    method: 'GET',
    params
  })
}
// 云证书产品提交打款记录
export function setPaymentSave (data) {
  return request({
    url: '/portal/payment/save',
    method: 'POST',
    data
  })
}

// 获得该产品可选购买次数列表
export function getTimesList (params) {
  return request({
    url: '/portal/product/times-list',
    method: 'GET',
    params
  })
}

// 获取当前订单微信付款二维码
export function getPayQrcode (params) {
  return request({
    url: '/portal/payment/generate-pay-qrcode',
    method: 'GET',
    skipErr: true,
    params
  })
}

// 查询是否支付完成
export function getOrderIsPay (params) {
  return request({
    url: '/portal/payment/get-order-is-pay',
    method: 'GET',
    skipErr: true,
    params
  })
}

// 查询身份实名产品或云证书产品code
export function getCodeList (params) {
  return request({
    url: '/portal/product/code-list',
    method: 'GET',
    params
  })
}
