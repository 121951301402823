/*
 * require.context(directory, useSub, reg)
 * 参数： 读取文件的路径  是否遍历文件的子目录 匹配文件的正则表达式
 * 返回： 函数；属性：resolve  keys * resolve: 是一个函数，他返回的是被解析模块的id ，接受一个参数request * keys: 也是一个函数，他返回的是一个数组，该数组是由所有可能被上下文模块解析的请求对象组成
 */
const modulesFiles = require.context('./modules', false, /\.js$/); // 第二个argument为false，不处理子目录
/*
 * reduce((total, currentValue, currentIndex, arr)=>{}, initVal)
 * 参数： 初始值|计算结束后的返回值  当前元素 
 */
const apis = modulesFiles.keys().reduce((modules, modulePath) => {
  // 执行modulesFiles 函数，返回一个对象{default: {//文件内容}, _esModule:true}
  const value = modulesFiles(modulePath);
  // set './app.js' => 'app'
  //   const moduleName = value.moduleName || modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  //   modules[moduleName] = value;
  modules = {
    ...modules,
    ...value
  }
  return modules
}, {});

export default apis;