<template>
  <div>
    <transition name="el-fade-in">
      <div v-show="loginShow" class="login-bg">
        <div class="form-box">
          <div class="close" @click="loginShow=false">
            <svg-icon class="icon" icon-class="close-icon" />
          </div>
          <div class="title">
            登录
          </div>
          <el-form ref="form" hide-required-asterisk label-width="63px" label-position="left" :rules="rules" :model="loginForm">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="loginForm.mobile"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="verCode">
              <el-input v-model="loginForm.verCode" maxlength="6">
                <el-button slot="suffix" size="medium" :disabled="countDown>0" @click="getCode" type="primary">{{countDown>0?`重新获取(${countDown})`:'获取验证码'}}</el-button>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button class="submitButn" @click="login" :loading="loading" type="primary">立即登录/注册</el-button>
          <div class="hint" :class="{animation:animation}">
            <el-checkbox v-model="agreementChecked"></el-checkbox>
            <div>
              <span>我已阅读并同意</span>
              <a @click="protocolShow='agreement'">《用户协议》</a>
              <span>、</span>
              <a @click="protocolShow='privacy'">《隐私政策》</a>
              <span>及</span>
              <a @click="protocolShow='application'">《数字证书申请及使用协议》</a>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <ProtocolDialog v-model="protocolShow" />
  </div>
</template>

<script>
import { validMobile } from '@/utils/validate'
import ProtocolDialog from '@/views/protocol/dialog.vue'
export default {
  components: { ProtocolDialog },
  data() {
    return {
      loading: false,
      animation: false,
      agreementChecked: false,
      loginShow: false,
      protocolShow: false,
      loginForm: {
        mobile: '',
        verCode: '',
        smsToken: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            message: '请输入正确的手机号码',
            validator: validMobile,
            trigger: 'blur'
          }
        ],
        verCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      intervals: [],
      countDown: 0
    }
  },
  computed: {},
  created() {
    this.$bus.$on('cgLoginShow', val => {
      this.loginShow = val
    })
  },
  methods: {
    getCode() {
      this.$refs.form.validateField('mobile', err => {
        if (!err) {
          this.$tencentCaptcha(val => {
            this.countDown = 60
            val['mobile'] = this.loginForm.mobile
            this.$api
              .getLoginverCode(val)
              .then(res => {
                this.loginForm.smsToken = res.data.sms_token
                this.intervals.push(
                  setInterval(() => {
                    this.countDown--
                    if (this.countDown == 0) {
                      this.clearInterval()
                    }
                  }, 1000)
                )
              })
              .catch(() => {
                this.countDown = 0
              })
          })
        }
      })
    },
    login() {
      this.$refs.form.validate(valid => {
        if (this.agreementChecked) {
          if (valid) {
            this.loading = true
            this.$api
              .getAccountToken(this.loginForm)
              .then(res => {
                let token_type = res.data.oAuthTokenInfo.token_type
                let type =
                  token_type.charAt(0).toUpperCase() + token_type.slice(1)
                let token = type + ' ' + res.data.oAuthTokenInfo.access_token
                let expires = res.data.oAuthTokenInfo.expires_in + 's'
                this.$store.commit('SET_TOKEN', token, expires)
                sessionStorage.setItem('token', token)
                this.$store.commit('user/setUserMobile', this.loginForm.mobile)
                if (res.data.verified) {
                  this.$bus.$emit('afterLogin')
                  this.$bus.$off('afterLogin')
                  this.getDataList()
                } else {
                  this.$store.commit('SET_BUSINESSLISTDATA', null)
                  this.$confirm(
                    '当前账号未完成管理员实名认证，实名之后才可办理业务。',
                    '提示',
                    {
                      confirmButtonText: '实名认证',
                      cancelButtonText: '暂不认证',
                      type: 'warning'
                    }
                  )
                    .then(() => {
                      this.$router.push({ name: 'personHome' })
                    })
                    .catch(() => {})
                }
                this.loading = false
                this.loginShow = false
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          this.loading = false
          this.hintAnimation()
        }
      })
    },
    getDataList() {
      let data = {
        pageIndex: 1,
        pageSize: 10,
        status: '1,3,4,6,7,14',
        orderColumn: 'lastUpdatedDate'
      }
      this.$api.getTransactionListPage(data).then(res => {
        this.$store.commit('SET_BUSINESSLISTDATA', res.data)
        // this.transaction_list = res.data
      })
    },
    clearInterval() {
      for (let i of this.intervals) {
        clearInterval(i)
      }
    },
    hintAnimation() {
      this.animation = true
      setTimeout(() => {
        this.animation = false
      }, 500)
    }
  },
  watch: {
    loginShow() {
      this.clearInterval()
      if (!this.loginShow) {
        this.loginForm = {
          mobile: '',
          verCode: '',
          smsToken: ''
        }
        this.countDown = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes shake {
  0%,
  100% {
    transform: translate(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-8px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(8px);
  }
}
// @keyframes shake {
//   0%,
//   100% {
//     transform: scale(1);
//   }

//   50% {
//     transform: scale(1.1);
//   }
// }
.login-bg {
  background-color: rgba(0, 0, 0, 0.29);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;

  .form-box {
    width: 510px;
    background: #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(66, 152, 249, 0.02);
    border-radius: 4px;
    position: absolute;
    top: 40%;
    padding: 50px 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #303b50;
      line-height: 20px;
      letter-spacing: 2px;
      margin-bottom: 50px;
      text-align: center;
    }
    ::v-deep.el-form {
      margin-bottom: 60px;
      .el-form-item__label,
      .el-input__inner {
        height: 50px;
        border-radius: 0;
        line-height: 50px;
      }
      .el-input__suffix {
        line-height: 50px;
      }
    }
    .submitButn {
      width: 100%;
      margin-bottom: 12px;
      padding: 17px 0;
    }
    .hint {
      display: flex;
      font-size: 14px;
      color: #333333;
      line-height: 26px;
      letter-spacing: 1px;
      &.animation {
        animation: shake 0.5s;
      }
      .el-checkbox {
        margin-right: 8px;
      }
    }
    .close {
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      background: #ffffff;
      border: 1px solid #aeb5be;
      position: absolute;
      color: #aeb5be;
      font-size: 20px;
      right: -40px;
      top: -16px;
      cursor: pointer;
      &:hover {
        color: $primaryColor;
      }
    }
  }
}
</style>