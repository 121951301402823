/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(s)
}
export function validEmail (rule, value, callback) {
  if (rule.required && !value) {
    callback(new Error('邮箱不能为空'));
  } else {
    if (!isEmail(value) && value) {
      callback(new Error('请输入正确的邮箱'));
    } else {
      callback();
    }
  }
}

/**
 * 8-16位密码
 * @param {*} s
 */
export function isPassword (s) {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(s)
}

/**
 * 验证码
 * @param {*} s
 */
export function isVercode (s) {
  return /^[0-9]{6}$/.test(s)
}

/**
 * 验证码
 * @param {*} s
 */
export function isIp (s) {
  return /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1(3|4|5|6|7|8|9)\d{9}$/.test(s)
}
export function validMobile (rule, value, callback) {
  if (rule.required && !value) {
    callback(new Error('请输入手机号'));
  } else {
    if (!isMobile(value) && value) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  }
}
/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 姓名格式校验
 * @export
 * @param {*} s
 */
export function isName (s) {
  const nameRule = /^[\u4e00-\u9fa5]{2,4}$/
  return nameRule.test(s)
}
export function validName (rule, value, callback) {
  if (rule.required && !value) {
    callback(new Error('请输入姓名'));
  } else {
    if (!isName(value) && value) {
      callback(new Error('请输入正确的姓名'));
    } else {
      callback();
    }
  }
}
/**
 * 身份证验证
 * @export
 * @param {*} e
 */
export function isIDcard (e) {
  const cardRule = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
  return cardRule.test(e)
}
export function validIDcard (rule, value, callback) {
  if (rule.required && !value) {
    callback(new Error('证件号不能为空'));
  } else {
    if (!isIDcard(value) && value) {
      callback(new Error('请输入正确的证件号'));
    } else {
      callback();
    }
  }
}
/**
 * 18位统一社会信用代码
 * @export
 * @param {*} e
 */
export function isCreditCode (e) {
  const regulation = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g
  return regulation.test(e)
}
export function validCreditCode (rule, value, callback) {
  if (rule.required && !value) {
    callback(new Error('证件号不能为空'));
  } else {
    if (!isCreditCode(value) && value) {
      callback(new Error('请输入正确的统一社会信用代码'));
    } else {
      callback();
    }
  }
}
/**
 * 普通营业执照
 * @export
 * @param {*} e
 * @returns
 */
export function isLicense (e) {
  let licenseRules = /^[A-Za-z0-9]\w{14}$/g
  if (licenseRules.test(e)) {
    return true
  } else {
    return false
  }
}

/**
 * 邮政编码校验
 * @export
 * @param {*} e
 */
export function isPostalCode (e) {
  const postalRule = /^[1-9]\d{5}$/
  if (postalRule.test(e)) {
    return true
  } else {
    return false
  }
}

/**
 * 手机号码中间四位隐藏
 * @export
 * @param {*} e
 */
export function coverPhone (e) {
  return e.substr(0, 3) + '****' + e.substr(7)
}

/**
 * 身份证中间四位隐藏
 * @export
 * @param {*} e
 */
export function coverIDCard (e) {
  return e.substr(0, 4) + '****' + e.substr(14)
}
