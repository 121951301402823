export default {
  path: '/personCenter',
  component: () => import('@/views/personCenter/person-center'),
  redirect: '/personHome',
  meta: {
    scrollToTop: true
  },
  children: [
    {
      path: '/personHome',
      name: 'personHome',
      component: () => import('@/views/personCenter/home/index.vue'),
    },
    {
      path: '/serviceCenter',
      name: 'serviceCenter',
      component: () => import('@/views/personCenter/serviceCenter/transaction.vue'),
    },
    {
      path: '/invoice',
      name: 'invoice',
      component: () => import('@/views/personCenter/invoice/invoice.vue'),
    },
    {
      path: '/certificate',
      name: 'certificate',
      component: () => import('@/views/personCenter/certificate/cert.vue'),
    },
    {
      path: '/integration',
      name: 'integration',
      component: () => import('@/views/personCenter/integrationCenter/center.vue'),
    }
  ]
}