<template>
  <div class="userAgreement">
    <h1>豸信CA网络身份认证集成服务协议</h1>
    <p>更新时间：2023年【8】月【01】日</p>
    <p>生效时间：2023年【8】月【01】日</p>
    <p>欢迎使用豸信CA实名认证服务！</p>
    <p>根据法律法规的相关要求，基于网络身份认证服务集成及使用，合同等需要，豸信CA需要您根据我们的指引完成网络身份认证集成业务的购买。为了使用网络身份认证服务（以下简称“本服务”），请您仔细阅读并确认《网络身份认证服务协议》（以下简称“本协议”）。</p>
    <p>本协议中与您的权益（可能）存在重大关系的条款（包括免除我们责任的条款、限制用户权利的条款、约定的争议解决方式及司法管辖条款等），豸信CA（以下或称“我们”）已采用字体加粗的方式来特别提醒您，请您留意重点查阅。您应当在确认充分阅读字体加粗条款，明确理解的前提下使用本服务。</p>
    <h2 style="margin-bottom:24px;">一、 网络身份认证集成服务内容</h2>
    <h3>1. 在本协议中所使用的下列词语，除非另有定义，应具有以下含义：</h3>
    <p>“网站”、“本平台”、“本服务”在无特别说明的情况下均指“网络身份认证集成认证服务”。</p>
    <p>“第三方”指独立于网络身份认证集成服务之外的某个独立的网站平台和机构。</p>
    <p>服务的具体内容由本平台根据实际情况提供。我们保留随时变更、中断或终止部分或全部网络服务的权利。</p>
    <p>2. 您购买此服务应完整提供必要的实名认证信息并确保所提供的实名认证信息真实准确、合法有效，不采用任何任何违法违规、不正当或欺骗手段使用本服务。</p>
    <h3>3. 如豸信CA发现或接举报发现您在使用本服务过程中存在违反本协议约定的行为，豸信CA有权中止或终止部分或全部豸信CA服务，包括但不限于对账号采取封禁、冻结等限制措施。</h3>
    <h3>4. 本服务提供的集成内容：</h3>
    <p>本服务提供网络身份认证的API接口服务，您购买本服务后，豸信CA为您提供相应的接口文档和SDKJar包，目前只支持Java开发环境。</p>
    <p>本服务的内容包括：身份证实名认证，运营商三要素验证，银行卡四要素验证，人脸识别，企业三要素校验，企业四要素校验，法定代表人认证，您可以根据自己的需求进行购买。</p>
    <p>本服务接入流程为：登陆官网-个人实名-企业实名-网络身份认证集成服务-选择需要的服务内容-手动勾选同意服务协议-申请接入-付款-技术对接-调用信息。</p>

    <h2 style="margin-bottom:24px;">二、隐私保护</h2>
    <p>1. 您在认证时提交给本平台的认证资料和认证信息，即不可撤销的授权由本平台保留。本平台承诺除法定或约定的事由外，不公开或编辑或透露您的认证资料及保存在本平台的非公开内容用于商业目的，但下列情况除外：</p>
    <p>（1）您授权本平台透露的相关信息；</p>
    <p>（2）本平台向国家司法及行政机关提供；</p>
    <p>（3）本平台向本平台关联企业提供；</p>
    <p>（4）第三方和本平台一起为用户提供服务时，该第三方向您提供服务所需的相关信息。</p>
    <p>2. 我们将采取商业上合理的方式以保护您个人资料的安全。我们将使用通常可以获得的安全技术和程序来保护您的个人资料不被未经授权的访问、使用或泄漏。</p>
    <p>3.您成功集成网络身份认证服务后，我们不获取任何关于您平台上的用户资料和信息。</p>
    <h2 style="margin-bottom:24px;">三、免责声明</h2>
    <h3>下列情况时本平台无需承担任何责任：</h3>
    <p>1. 您明确同意使用我们的网络服务，所存在的风险将完全由您自己承担；因您使用我们网络服务而产生的一切后果也由您自己承担，我们对您不承担任何责任。</p>
    <p>2. 由于第三方原因(包括不可抗力，例如国际出口的主干线路及国际出口电信提供商一方出现故障、黑客攻击、电信部门技术调整导致之重大影响、因政府管制导致的暂时关闭、病毒侵袭等影响互联网正常运行、火灾、水灾、雷击、地震、洪水、台风、龙卷风、火山爆发、瘟疫和传染病流行、罢工、战争或暴力行为或类似事件等)及其他非因本平台过错而造成的认证信息泄露、丢失、被盗用或被篡改等。</p>
    <p>3. 本平台用户（包括未成年人用户）向本平台提供错误、不完整、不实信息等造成不能通过认证或遭受任何其他损失，概与本平台无关。</p>
    <h2 style="margin-bottom:24px;">四、修改和解释权</h2>
    <p>1. 本中心有权单方随时修改或变更本协议内容，并通网络身份认证集成服务平台公告变更后的协议文本，无需单独通知您。本协议进行任何修改或变更后，您还继续使用本证服务的，即代表您已阅读、了解并同意接受变更后的协议内容，且不得因此而要求任何补偿或赔偿；您如果不同意变更后的协议内容，可停止使用我们所提供的服务。</p>
    <p>2. 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国法律。本协议项下因与中华人民共和国现行法律冲突而导致部分无效，不影响其他部分的效力。</p>
    <p>3. 本协议未尽事宜，或与国家、地方有关规定相悖的，按有关规定执行。</p>
    <p>4. 我们保留对本协议条款、产品和服务以及我们所提供的产品和服务的相关官方网站的最终解释权。</p>
  </div>
</template>

<script>
export default {
  props: ['showAgreement'],
  methods: {
    closeAgreement () {
      this.$emit('closeAgreement', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.userAgreement {
  h3 {
    text-indent: 2em;
    font-size: 14px;
    line-height: 28px;
  }
}
</style>