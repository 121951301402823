<template>
  <transition name="el-fade-in">
    <div v-show="certificationShow" class="certification">
      <div class="form-box">
        <el-button class="later-on" @click="certificationShow=false" type="text">稍后实名</el-button>
        <div v-show="step===1">
          <div class="title">
            经办人实名
            <div class="hint">业务经办人实名认证，实名后才可以办理业务哦</div>
          </div>
          <el-form ref="form" label-width="80px" label-position="right" :rules="rules" :model="certificationForm">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="certificationForm.name" maxlength="30" minlength="2"></el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="identNo">
              <el-input v-model="certificationForm.identNo" maxlength="18"></el-input>
            </el-form-item>
          </el-form>
          <el-button class="submitButn" @click="certification" type="primary">提交</el-button>
        </div>
        <div v-show="step===2">
          <div class="title">
            刷脸验证
            <div class="hint">业务经办人实名认证，实名后才可以办理业务哦</div>
          </div>
          <div class="qr-code" v-loading='!qr_code_url'>
            <img :src="qr_code_url" alt="刷脸二维码" srcset="">
            <div v-if="isScanning" class="isScanning">
              <img src="@/assets/image/personCenter/accomplish.png" alt="已扫码">
              已扫码
            </div>
          </div>
          <el-button @click="getCreateFaceUrl" class="refresh" type="text">刷新二维码</el-button>
        </div>
        <div v-show="step===3" class="finish">
          <img src="@/assets/image/personCenter/accomplish.png" alt="成功">
          <div>认证成功</div>
          <el-button class="submitButn" @click="complete" type="primary">好的</el-button>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import { validName, validIDcard } from '@/utils/validate'
export default {
  data () {
    return {
      animation: false,
      agreementChecked: false,
      certificationShow: false,
      step: 1,
      qr_code_url: '',
      certificationForm: {
        name: '',
        identNo: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            validator: validName,
            message: '请输入真实姓名',
            trigger: 'blur'
          }
        ],
        identNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          {
            validator: validIDcard,
            message: '请输入正确的18位身份证号码',
            trigger: 'blur'
          }
        ]
      },
      isScanning: false,
      intervals: [],
      countDown: 0
    }
  },
  computed: {},
  created () {
    this.$bus.$on('cgCertificationShow', val => {
      this.certificationShow = val
    })
  },
  methods: {
    certification () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$api
            .saveAuthInfo(this.certificationForm)
            .then(res => {
              this.authPersonId = res.data
              this.getCreateFaceUrl()
            })
            .catch(() => {
              this.certificationShow = false
              this.isLoad = false
            })
        }
      })
    },
    getCreateFaceUrl () {
      this.isLoad = true
      this.clearInterval()
      this.$api
        .getCreateFaceUrl({ authPersonId: this.authPersonId })
        .then(res => {
          this.qr_code_url = URL.createObjectURL(res.data)
          this.isScanning = false
          this.step = 2
          this.isLoad = false
          this.intervals.push(
            setInterval(() => {
              this.getAuthPersonStatus()
            }, 3000)
          )
        })
        .catch(() => {
          this.isLoad = false
        })
    },
    getAuthPersonStatus () {
      this.$api
        .getAuthPersonStatus({ authPersonId: this.authPersonId })
        .then(res => {
          if (res.code && res.code === '0') {
            if (res.data.faceScan === 1) {
              this.isScanning = true
            } else {
              this.isScanning = false
            }
            if (res.data.status !== 0) {
              this.clearInterval()
            }
            if (res.data.status === 1) {
              this.step = 3
            }
          } else {
            this.clearInterval()
          }
        })
        .catch(() => {
          this.clearInterval()
        })
    },
    complete () {
      this.certificationShow = false
      this.$bus.$emit('afterLogin')
      this.$bus.$off('afterLogin')
    },
    clearInterval () {
      for (let i of this.intervals) {
        clearInterval(i)
      }
    }
  },
  beforeDestroy () {
    this.clearInterval()
  },
  watch: {
    certificationShow () {
      this.step = 1
      this.isScanning = false
      this.qr_code_url = ''
      this.certificationForm = {
        name: '',
        identNo: ''
      }
      this.clearInterval()
      if (!this.certificationShow) {
        this.$bus.$off('afterLogin')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.certification {
  background-color: rgba(0, 0, 0, 0.29);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
  .form-box {
    width: 607px;
    background: #ffffff;
    box-shadow: 0px 8px 24px 0px rgba(66, 152, 249, 0.02);
    border-radius: 4px;
    position: absolute;
    top: 40%;
    padding: 40px 90px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .later-on {
      position: absolute;
      top: 30px;
      right: 32px;
      padding: 0;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #303b50;
      line-height: 20px;
      letter-spacing: 2px;
      margin-bottom: 48px;
      text-align: center;
      .hint {
        text-align: center;
        font-size: 14px;
        color: #6c737b;
        line-height: 19px;
        font-weight: normal;
        letter-spacing: 1px;
        margin-top: 16px;
      }
    }
    ::v-deep.el-form {
      margin-bottom: 48px;
      .el-form-item {
        margin-bottom: 32px;
      }
      .el-form-item__label,
      .el-input__inner {
        height: 50px;
        border-radius: 2px;
        line-height: 50px;
      }
      .el-input__suffix {
        line-height: 50px;
      }
    }
    .submitButn {
      width: 152px;
      margin: 0 auto;
      display: block;
      margin-bottom: 8px;
    }
    .qr-code {
      width: 148px;
      height: 148px;
      background: #ffffff;
      border: 1px solid #303b50;
      padding: 10px;
      margin: 0 auto;
      margin-bottom: 16px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .isScanning {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.73);
      left: 0;
      top: 0;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      padding-top: 40px;
      text-align: center;
      img {
        width: 36px;
        height: 36px;
        background-color: #fff;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 12px;
        display: block;
      }
    }
    .refresh {
      padding: 0;
    }
    .finish {
      text-align: center;
      font-size: 20px;
      color: #303b50;
      line-height: 26px;
      letter-spacing: 1px;
      img {
        width: 48px;
        height: 48px;
        margin-bottom: 24px;
        margin-top: 28px;
      }
      .el-button {
        width: 152px;
        margin-bottom: 32px;
        margin-top: 78px;
      }
    }
  }
}
</style>