import request from '@/utils/request'
// 获取印章业务单费用
export function getTransFee (params) {
  return request({
    url: '/portal/seal/trans/fee',
    method: 'GET',
    params
  })
}
// 印模抠图
export function patternMatting (data) {
  return request({
    url: '/portal/transaction/pattern-matting',
    method: 'POST',
    data
  })
}
// 印章申请表附件上传
export function applyFileUpload (data) {
  return request({
    url: '/portal/seal/trans/apply-file-upload',
    method: 'POST',
    headers: {
      "Content-Type": 'multipart/form-data'
    },
    data,
    timeout: 1000 * 60 * 5
  })
}
// 获取短信验证码
export function getSmsVerCode (params) {
  return request({
    url: '/portal/seal/get-sms-ver-code',
    method: 'get',
    params
  })
}
// 印章新办业务单提交
export function postSubmitApply (data) {
  return request({
    url: '/portal/seal/trans/submit-apply',
    method: 'POST',
    data
  })
}
// 获取手写签二维码
export function getQRCode (params) {
  return request({
    url: '/portal/api/pattern/write/qr_code',
    method: 'GET',
    params
  })
}
// 获取手写章图片
export function getHandWritten (params) {
  return request({
    url: '/portal/api/pattern/write/get_img',
    method: 'GET',
    params,
    skipErr: true
  })
}