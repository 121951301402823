export default {
  path: '/service',
  component: () => import('@/views/service'),
  children: [{
    path: '/certApply',
    name: 'certApply',
    component: () => import('@/views/service/certApply'),
  }, {
    path: '/certRenewal',
    name: 'certRenewal',
    component: () => import('@/views/service/certRenewal'),
  }, {
    path: '/certUnlock',
    name: 'certUnlock',
    component: () => import('@/views/service/certUnlock'),
  }, {
    path: '/certChange',
    name: 'certChange',
    component: () => import('@/views/service/certChange'),
  }, {
    path: '/certReissue',
    name: 'certReissue',
    component: () => import('@/views/service/certReissue'),
  }, {
    path: '/certCancel',
    name: 'certCancel',
    component: () => import('@/views/service/certCancel'),
  }, {
    path: '/certQuery',
    name: 'certQuery',
    component: () => import('@/views/service/certQuery'),
  }, {
    path: '/fileVerify',
    name: 'fileVerify',
    component: () => import('@/views/service/fileVerify'),
  }, {
    path: '/verifyReport',
    name: 'verifyReport',
    component: () => import('@/views/service/verifyReport')
  }
  ]
}