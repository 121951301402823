import request from '@/utils/request'
import store from '@/store'
export function getLoginverCode (params) {
  return request({
    url: '/auth/oauth/account/login/verCode',
    method: 'get',
    params
  })
}
export function getAccountToken (params) {
  const username = 'portal'
  const password = '55146f3f15146108a3ad5f1d17f0f7b9'
  const credentials = btoa(username + ':' + password)
  const basicAuth = 'Basic ' + credentials
  return request({
    url: '/auth/oauth/account/token',
    method: 'post',
    headers: {
      Authorization: basicAuth,
    },
    params
  })
}
export function getAccountInfo (params) {
  return request({
    url: '/portal/account/authentication/account',
    method: 'get',
    params
  }).then(res => {
    store.commit('user/SET_USERINFO', res.data)
    return res
  })
}
export function getIntegratedList (params) {
  return request({
    url: '/portal/product/integrated-list',
    method: 'get',
    params
  })
}