export default {
  namespaced: true,
  state: {
    userInfo: {},
    userMobile: '',
    secretList: [],
    orderNo: '',
    appId: ''
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    setUserMobile: (state, mobile) => {
      state.userMobile = mobile
    },
    setSecretList: (state, secretList) => {
      state.secretList = secretList
    },
    setOrderNo: (state, orderNo) => {
      state.orderNo = orderNo
    },
    seAppId: (state, appId) => {
      state.appId = appId
    },
  },
  actions: {

  }
}
