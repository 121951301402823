import axios from 'axios'
// import resWriteList from './resWriteList'
import router from '@/router'
import CONFIG from '@/config'
import store from '@/store'
import vueInstance from '@/main'
// import Vue from 'vue'
import {
  Message, MessageBox
} from 'element-ui'
// MessageBox
const service = axios.create({
  baseURL: CONFIG.baseURL,
  timeout: 1000 * 30, // request timeout
  headers: {
    // 'content-type': 'application/x-www-form-urlencoded'
    'lb-s-version': 'jhh'
  },
})
function goLogin () {
  sessionStorage.clear()
  store.commit('SET_TOKEN', null)
  if (router.currentRoute.path.indexOf('/portal') === -1) {
    router.push({
      name: 'home'
    })
  }
  vueInstance.$bus.$emit('cgLoginShow', true)
}
// request interceptor
service.interceptors.request.use(
  (config) => {
    if (!(config.headers && config.headers['Authorization'])) {
      config.headers['Authorization'] = store.state.token // 请求头带上token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 302) {
      goLogin()
      return Promise.reject(res)
    }
    // if (resWriteList.some(item => response.config.url.includes(item))) {
    //   return res
    // }
    if (response.config.responseType === 'blob') {
      if (res.type.indexOf('application/json') !== -1) {
        let reader = new FileReader()
        reader.readAsText(res)
        reader.onload = (e) => {
          let message = JSON.parse(e.target.result).msg
          Message({
            message,
            type: 'error',
            duration: 3 * 1000
          })
        }
        return Promise.reject(res)
      } else {
        return response
      }
    }
    // 常规请求
    if (res.code != '0') {
      // skipErr  是否跳过错误处理
      let skipErr = response.config.skipErr || false
      if (skipErr && res.code != 500) {
        return res
      }
      // hintType 错误提示方式  popup弹窗提示  bubble气泡提示  默认气泡提示
      let hintType = response.config.hintType || 'bubble'
      if (hintType === 'popup') {
        MessageBox.alert(res.code == 500 ? '未知系统异常 ' : res.msg || 'Error', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
      } else {
        Message({
          message: res.code == 500 ? '未知系统异常 ' : res.msg || 'Error',
          type: 'error',
          duration: 3 * 1000
        })
      }
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    let errNew = ''
    if (error.response) {
      errNew = error.response.data && error.response.data.msg
    } else {
      errNew = error.message
    }
    Message({
      message: errNew,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service